import { Box } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { Edit } from "@mui/icons-material";

import MTable, { StyledTableCell, StyledTableRow } from "../common/MTable";

import { createHeaders } from "../../lib/utils/utils";
import { ReconfirmBtn } from "../common/Buttons";
import MDialogEmailTemplate from "./MDialogEmailTemplate";

const headers = [
  createHeaders("Name", false, "name"),
  createHeaders("Subject", false, "subject"),
  createHeaders("Action", false, null),
];

const TemplatesList = ({ useEmailTemplatesHook }) => {
  const {
    templates,
    handleTestBtn,
    editEmailTemplate,
    templatesLength,
    setTableConfig,
    tableConfig,
    fetchDeleteEmailTemplate,
  } = useEmailTemplatesHook;
  return (
    <>
      <Box sx={{ maxWidth: "1240px", margin: "0 auto" }}>
        <MTable
          tableConfig={tableConfig}
          setTableConfig={setTableConfig}
          search={false}
          headers={headers}
          records={templates}
          totalLength={templatesLength}
          rowRenderer={(record) => (
            <StyledTableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={record.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <StyledTableCell component="td" scope="row" padding="none">
                {record.name}
              </StyledTableCell>
              <StyledTableCell align="center">{record.title}</StyledTableCell>
              <StyledTableCell align="center" width="370px">
                <MDialogEmailTemplate
                  buttonLabel="Edit"
                  btnHandler={editEmailTemplate}
                  data={{
                    id: record.id,
                    title: record.title,
                    name: record.name,
                    content: record.content,
                  }}
                  icon={<Edit />}
                />
                <ReconfirmBtn
                  btnTitle="Test"
                  desc="Are you sure to send?"
                  handleClick={() => handleTestBtn(record.id)}
                  icon={<SendIcon />}
                  color="primary"
                />
                <ReconfirmBtn
                  btnTitle="Delete"
                  desc="Delete This Template?"
                  handleClick={() => fetchDeleteEmailTemplate(record.id)}
                  color="secondary"
                />
              </StyledTableCell>
            </StyledTableRow>
          )}
        />
      </Box>
    </>
  );
};

export default TemplatesList;
