import { useEffect, useState } from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material"

import { validateForm, validateEmail } from "../../../lib/utils/utils"
import { PrimaryBtn, PrimaryIconBtn } from "../../common/Buttons"

const originError = {
  companyName: false,
  emailAddress: false,
}

const FormDialog = ({
  buttonLabel,
  title,
  desc = "",
  btnHandler,
  data,
  icon,
  customWidth,
  disabled,
}) => {
  const [open, setOpen] = useState(false)
  const [input, setInput] = useState(data)
  const [error, setError] = useState(originError)

  const validateInput = (name, value) => {
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" }

      switch (name) {
        case "companyName":
          if (!value) {
            stateObj[name] = "Please enter Company Name"
          }
          if (
            value &&
            (/\\/gm.test(value) || /[\b\f\n\r\t\v\0]/gm.test(value))
          ) {
            stateObj[name] = "Company Name cannot include \\"
          }
          break
        case "emailAddress":
          if (!value) {
            stateObj[name] = "Please enter Email Address"
          } else if (!validateEmail(value)) {
            stateObj[name] = "Please enter correct Email"
          }
          break
        default:
          break
      }
      return stateObj
    })
  }
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setInput({
      ...input,
      [name]: value,
    })
    validateInput(name, value)
  }
  const handleConfirm = () => {
    if (validateForm(input, error)) {
      btnHandler(input)
      handleClose()
    }
  }
  const handleClose = () => {
    setOpen(false)
    setInput(data)
    setError(originError)
  }

  useEffect(() => {
    setInput(data)
  }, [data])

  return (
    <>
      <PrimaryIconBtn
        icon={icon}
        btnLabel={buttonLabel}
        handleClick={handleClickOpen}
        customWidth={customWidth}
        disabled={disabled}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{desc}</DialogContentText>
          <TextField
            margin="dense"
            id="companyName"
            name="companyName"
            type="text"
            fullWidth
            variant="standard"
            value={input.companyName}
            onChange={(e) => handleInputChange(e)}
            label="Name"
            helperText={error.companyName ? error.companyName : ""}
            error={!!error.companyName}
          />
          <TextField
            margin="dense"
            id="emailAddress"
            name="emailAddress"
            type="text"
            fullWidth
            variant="standard"
            value={input.emailAddress}
            onChange={(e) => handleInputChange(e)}
            label="Email"
            helperText={error.emailAddress ? error.emailAddress : ""}
            error={!!error.emailAddress}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start" }}>
          <PrimaryBtn
            btnLabel="Confirm"
            handleClick={handleConfirm}
            disabled={
              !validateForm(
                {
                  companyName: input.companyName,
                  emailAddress: input.emailAddress,
                },
                error
              )
            }
          />
          <PrimaryBtn btnLabel="Cancel" handleClick={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FormDialog
