import { useEffect, useState } from "react";
import { PrimaryBtn } from "../common/Buttons";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { validateForm, validateEmail } from "../../lib/utils/utils";
import { MInput } from "../common/MInputs";
import { RowLabelRenderer } from "../common/RowLabelRenderer";
const originError = {
  name: false,
  email: false,
  userGroup: false,
};

export default function FormDialog({
  buttonLabel,
  title,
  desc = "",
  btnHandler,
  data,
}) {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(data);
  const [error, setError] = useState(originError);

  const validateInput = (name, value) => {
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "name":
          if (!value) {
            stateObj[name] = "Please enter Username";
          }
          break;
        case "email":
          if (!value) {
            stateObj[name] = "Please enter Email";
          } else if (!validateEmail(value)) {
            stateObj[name] = "Please enter correct Email";
          }
          break;
        case "userGroupId":
          if (!value) {
            stateObj[name] = "Please select one Group";
          }
          break;
        default:
          break;
      }
      return stateObj;
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
    validateInput(name, value);
  };

  const handleConfirm = () => {
    btnHandler({ ...input });
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setInput(data);
    setError(originError);
  };
  useEffect(() => {
    setInput(data);
  }, [data]);
  return (
    input && (
      <>
        <PrimaryBtn btnLabel={buttonLabel} handleClick={handleClickOpen} />
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="600px"
          width="600px"
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{desc}</DialogContentText>
            <Grid container rowSpacing={2} sx={{ margin: "auto" }}>
              <RowLabelRenderer label="Name">
                <MInput
                  name="name"
                  value={input.name}
                  handleChange={(e) => handleInputChange(e)}
                  readOnly={false}
                  errorText={error.name ? error.name : ""}
                  error={!!error.name}
                />
              </RowLabelRenderer>
              <RowLabelRenderer label="Email">
                <MInput
                  name="email"
                  value={input.email}
                  handleChange={(e) => handleInputChange(e)}
                  readOnly={false}
                  errorText={error.email ? error.email : ""}
                  error={!!error.email}
                />
              </RowLabelRenderer>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "flex-start" }}>
            <PrimaryBtn
              btnLabel="Confirm"
              handleClick={handleConfirm}
              disabled={
                !validateForm(
                  {
                    name: input.name,
                    email: input.email,
                    userGroupId: input.userGroupId,
                  },
                  error
                )
              }
            />
            <PrimaryBtn btnLabel="Cancel" handleClick={handleClose} />
          </DialogActions>
        </Dialog>
      </>
    )
  );
}
