import { axiosInstance } from "./base";

export const getAllUserGroups = ({
  q = null,
  s = null,
  n = null,
  order = null,
  filters = null,
}) => {
  return axiosInstance.get("/api/userGroup", {
    params: {
      q,
      s,
      n,
      order,
      filters,
    },
  });
};

export const createUserGroups = (name) => {
  return axiosInstance.post("/api/userGroup", { name });
};

export const deleteUserGroups = (id) => {
  return axiosInstance.delete(`/api/userGroup/${id}`);
};

export const updateUserGroup = (id, name) => {
  return axiosInstance.post(`/api/userGroup/${id}`, { id, name });
};

export const getUserGroupsRole = () => {
  return axiosInstance.get(`/api/userGroup/listByUserRole`);
};
