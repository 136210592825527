import { useAuth } from "../lib/context/AuthContext";
import { useNavigate } from "react-router";
import { useEffect } from "react";

const HomePage = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const isAuthenticated = auth.isAuthenticated;
  const isSupplier = auth.isSupplier();
  const isAdmin = auth.isAdmin();

  useEffect(() => {
    if (isAuthenticated) {
      if (isSupplier) {
        navigate("/supplier/submission");
      } else if (isAdmin) {
        navigate("/rfq");
      }
    } else {
      navigate("/login");
    }
  }, [isAdmin, isAuthenticated, isSupplier, navigate]);

  return <></>;
};

export default HomePage;
