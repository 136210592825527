import { useEffect } from "react";
import { format, isValid } from "date-fns";
import ArticleIcon from "@mui/icons-material/Article";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { LockOpen } from "@mui/icons-material";

import { Box, Grid } from "@mui/material";

import MDialogUnlock from "./MDialogUnlock";

import MTable, { StyledTableCell, StyledTableRow } from "../common/MTable";
import {
  checkState,
  createHeaders,
  validDateTimeRange,
} from "../../lib/utils/utils";
import { PrimaryIconBtn, ReconfirmBtn } from "../common/Buttons";
import { useRfqs } from "../../lib/context/RfqsContext";
import { MDropdown } from "../common/MInputs";
import { MDatePickerWithoutTime } from "../common/MDatePicker";
import { RowLabelRenderer } from "../common/RowLabelRenderer";
import { useNavigate } from "react-router";
import { useAuth } from "../../lib/context/AuthContext";

const headers = [
  createHeaders("RFQ Name", false, "name"),
  createHeaders("Reference No.", false, "referenceNumber"),
  createHeaders("Site", false, "siteApplied"),
  createHeaders("Owner", false, "owner"),
  createHeaders("Start Time", false, "startTime"),
  createHeaders("Due Time", false, "dueTime"),
  createHeaders("Status", false, "state"),
  createHeaders("No. of invitee", false, null),
  createHeaders("Return bids", false, null),
  createHeaders("Action", false, null),
];

const stateOptions = [
  { id: "0", name: "Preparing" },
  { id: "1", name: "Running" },
  { id: "2", name: "Completed" },
  { id: "3", name: "Unlocked" },
  //{ id: "4", name: "Locked" },
  { id: "5", name: "Terminated" },
  { id: "6", name: "All" },
];

const RfqsList = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const {
    rfqs,
    rfqsLength,
    fetchRfqsList,
    tableConfig,
    setTableConfig,
    deleteHandler,
    fetchUnLockRfqById,
    initTableConfig,
    fetchTerminateRfqById,
  } = useRfqs();

  useEffect(() => {
    fetchRfqsList();
  }, [fetchRfqsList]);
  return (
    <>
      <Box sx={{ maxWidth: "1240px", margin: "0 auto" }}>
        {rfqs && (
          <MTable
            tableConfig={tableConfig}
            setTableConfig={setTableConfig}
            search={true}
            headers={headers}
            records={rfqs}
            totalLength={rfqsLength}
            initTableConfig={initTableConfig}
            filtersRenderer={(tableConfig, setTableConfig) => {
              return (
                <>
                  <Grid
                    container
                    className="table"
                    rowSpacing={2}
                    sx={{ margin: "auto 0", padding: "0 20px", width: "80%" }}
                    columns={{ xs: 12 }}
                  >
                    <RowLabelRenderer
                      label="Status"
                      labelColumn={1}
                      InputColumn={11}
                    >
                      <MDropdown
                        name="state"
                        handleChange={(e) =>
                          //setFilters({ ...filters, state: e.target.value })
                          setTableConfig({
                            ...tableConfig,
                            filters: {
                              ...tableConfig.filters,
                              state: e.target.value,
                            },
                          })
                        }
                        options={stateOptions}
                        value={tableConfig.filters.state}
                      />
                    </RowLabelRenderer>
                    <RowLabelRenderer
                      label="From"
                      labelColumn={1}
                      InputColumn={4}
                    >
                      <MDatePickerWithoutTime
                        error={
                          !validDateTimeRange(
                            tableConfig.filters.startTime,
                            tableConfig.filters.dueTime
                          )
                        }
                        value={tableConfig.filters.startTime}
                        handleChange={(newValue) => {
                          if (newValue && isValid(newValue)) {
                            const newDate = new Date(newValue).toISOString();
                            return setTableConfig({
                              ...tableConfig,
                              filters: {
                                ...tableConfig.filters,
                                startTime: newDate,
                              },
                            });
                          }
                        }}
                      />
                    </RowLabelRenderer>
                    <Grid item xs={1} sx={{ alignSelf: "center" }}></Grid>

                    <RowLabelRenderer
                      label="To"
                      labelColumn={2}
                      InputColumn={4}
                    >
                      <MDatePickerWithoutTime
                        error={
                          !validDateTimeRange(
                            tableConfig.filters.startTime,
                            tableConfig.filters.dueTime
                          )
                        }
                        label=""
                        value={tableConfig.filters.dueTime}
                        handleChange={(newValue) => {
                          if (newValue && isValid(newValue)) {
                            const newDate = new Date(newValue).toISOString();
                            return setTableConfig({
                              ...tableConfig,
                              filters: {
                                ...tableConfig.filters,
                                dueTime: newDate,
                              },
                            });
                          }
                        }}
                      />
                    </RowLabelRenderer>
                  </Grid>
                </>
              );
            }}
            rowRenderer={(record) => (
              <>
                <StyledTableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={record.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell component="td" scope="row" padding="none">
                    <>{record.name}</>
                    {/*{record.state === 2 || record.state === 3 ? (*/}
                    {/*) : (*/}
                    {/*  <Link to={`/rfq/${record.id}`}>*/}
                    {/*    <CustomLink sx={{ "&:hover": { cursor: "pointer" } }}>*/}
                    {/*      {record.name}*/}
                    {/*    </CustomLink>*/}
                    {/*  </Link>*/}
                    {/*)}*/}
                  </StyledTableCell>
                  <StyledTableCell
                    component="td"
                    scope="row"
                    padding="none"
                    align="center"
                  >
                    {record?.referenceNumber || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    component="td"
                    scope="row"
                    padding="none"
                    align="center"
                  >
                    {record?.siteApplied || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    component="td"
                    scope="row"
                    padding="none"
                    align="center"
                  >
                    {record.owner}
                  </StyledTableCell>
                  <StyledTableCell
                    component="td"
                    scope="row"
                    padding="none"
                    align="center"
                  >
                    {format(new Date(record.startTime), "dd/MM/yyyy HH:mm")}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {format(new Date(record.dueTime), "dd/MM/yyyy HH:mm")}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {checkState(record)}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {record.noOfInvitees}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {record.returnBids}
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "25%" }}>
                    {(auth.isAdminRole() || auth.isPM()) && (
                      <PrimaryIconBtn
                        btnLabel="Open"
                        handleClick={() => {
                          if (record.state === 0 || record.state === 1) {
                            return navigate(`/rfq/${record.id}`);
                          }
                          if (
                            record.state === 3 ||
                            (record.state === 2 && record.returnBids >= 3)
                          ) {
                            return navigate(
                              `/rfq/${record.id}/summary?readMode=true&showQuotation=true`
                            );
                          } else if (
                            record.state === 2 &&
                            record.returnBids < 3
                          ) {
                            return navigate(
                              `/rfq/${record.id}/summary?readMode=true`
                            );
                          }
                        }}
                        disabled={record.state === 5}
                        icon={<ArticleIcon />}
                        // disabled={record.state === 0 || record.state === 1}
                      />
                    )}

                    {(auth.isAdminRole() || auth.isFC()) &&
                      record.state === 2 &&
                      record.returnBids < 3 && (
                        <MDialogUnlock
                          buttonLabel="Unlock"
                          title="Enter Password"
                          btnHandler={(input) =>
                            fetchUnLockRfqById(record.id, input)
                          }
                          icon={<LockOpen />}
                          name="password"
                          inputLabel="Password"
                          isDisabled={false}
                        />
                      )}

                    {auth.isFC() && (
                      <PrimaryIconBtn
                        btnLabel="Summary"
                        handleClick={() => {
                          if (record.state === 0 || record.state === 1) {
                            return navigate(`/rfq/${record.id}`);
                          }
                          return navigate(
                            `/rfq/${record.id}/summary?readMode=true${
                              record.state === 3 ||
                              (record.state === 2 && record.returnBids >= 3)
                                ? "&showQuotation=true"
                                : ""
                            }`
                          );
                        }}
                        disabled={record.state === 5}
                        icon={<ArticleIcon />}
                      />
                    )}
                    {auth.isAdminRole() && record.state === 1 && (
                      <ReconfirmBtn
                        btnTitle="Terminated"
                        desc="Terminate this RFQ?"
                        handleClick={() => fetchTerminateRfqById(record.id)}
                        icon={<CancelPresentationIcon />}
                      />
                    )}

                    {(auth.isAdminRole() || auth.isPM()) &&
                      record.state === 0 && (
                        <ReconfirmBtn
                          btnTitle="Delete"
                          desc="Delete this RFQ?"
                          handleClick={() => deleteHandler(record.id)}
                        />
                      )}
                  </StyledTableCell>
                </StyledTableRow>
              </>
            )}
          />
        )}
      </Box>
    </>
  );
};

export default RfqsList;
