import { Divider } from "@mui/material";
import { withAuthenticated } from "../../lib/context/AuthContext";
import UserProfile from "../../components/User/UserProfile";
import MPage from "../../components/common/MPage";

const UserIndex = () => {
  return (
    <>
      <MPage title="User Profile">
        <Divider sx={{ margin: "20px 0" }} />
        <UserProfile />
      </MPage>
    </>
  );
};

export default withAuthenticated(UserIndex);
