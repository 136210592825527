import {Box, InputBase} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {OutlinedBtn, PrimaryBtn} from "./Buttons";

const SearchBar = ({
										 value = "",
										 onChange,
										 onClickSearch,
										 onClickReset = () => onChange(""),
									 }) => {
	const formSubmit = (e) => {
		e.preventDefault();
		onClickSearch();
	};
	return (
		<Box
			sx={{
				maxWidth: "1240px",
				margin: "0 auto",
				backgroundColor: "#E6E6E6",
				p: 1,
				mt: 2,
			}}
			style={{
				//display: "flex",
				//justifyContent: "flex-start",
				backgroundColor: "inherit",
				alignItems: "center",
			}}
		>
			<form
				onSubmit={(e) => formSubmit(e)}
				style={{width: "100%", display: "flex", justifyContent: "flex-start"}}
			>
				<InputBase
					sx={{
						mx: 1,
						bgcolor: "white",
						width: "60%",
						borderRadius: "18px",
						padding: "6px 12px",
					}}
					value={value}
					placeholder="Search ..."
					onChange={(event) => onChange(event.target.value)}
					startAdornment={<SearchIcon sx={{color: "#C7C9CD"}}/>}
				/>
				<PrimaryBtn btnLabel={"Search"} handleClick={onClickSearch}/>
				<OutlinedBtn btnLabel={"Reset"} handleClick={onClickReset}/>
			</form>
		</Box>
	);
};

export default SearchBar;
