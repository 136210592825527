import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";

import LockResetIcon from "@mui/icons-material/LockReset";
import { PrimaryBtn, ReconfirmBtn } from "./Buttons";
import { validateForm, validateEmail } from "../../lib/utils/utils";
import { MDropdown, MInput } from "./MInputs";
import { RowLabelRenderer } from "./RowLabelRenderer";
import { useUsers } from "../../lib/hooks/useUsers";
const originError = {
  name: false,
  email: false,
  userGroup: false,
};

export default function FormDialog({
  buttonLabel,
  title,
  desc = "",
  btnHandler,
  data,
  groups,
  orgs,
}) {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(data);
  const [error, setError] = useState(originError);
  const { resetPassword } = useUsers();
  const fcGroup =
    groups &&
    groups.find((item) => item.name.toLowerCase() === "financial controller");
  const validateInput = (name, value) => {
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "name":
          if (!value) {
            stateObj[name] = "Please enter Username";
          }
          break;
        case "email":
          if (!value) {
            stateObj[name] = "Please enter Email";
          } else if (!validateEmail(value)) {
            stateObj[name] = "Please enter correct Email";
          }
          break;
        case "userGroupId":
          if (!value) {
            stateObj[name] = "Please select one Group";
          }
          break;
        default:
          break;
      }
      return stateObj;
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
    validateInput(name, value);
  };

  const handleConfirm = () => {
    let tempInput = input;
    if (input.userGroupId !== fcGroup.id) {
      tempInput = {
        ...tempInput,
        organizationId: "",
      };
    }
    btnHandler({ ...tempInput });
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setInput(data);
    setError(originError);
  };

  useEffect(() => {
    setInput(data);
  }, [data]);
  return (
    input && (
      <>
        <PrimaryBtn btnLabel={buttonLabel} handleClick={handleClickOpen} />
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="600px"
          width="600px"
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{desc}</DialogContentText>
            <Grid container rowSpacing={2} sx={{ margin: "auto" }}>
              <RowLabelRenderer label="Name">
                <MInput
                  name="name"
                  value={input.name}
                  handleChange={(e) => handleInputChange(e)}
                  readOnly={false}
                  errorText={error.name ? error.name : ""}
                  error={!!error.name}
                />
              </RowLabelRenderer>
              <RowLabelRenderer label="Email">
                <MInput
                  name="email"
                  value={input.email}
                  handleChange={(e) => handleInputChange(e)}
                  readOnly={false}
                  errorText={error.email ? error.email : ""}
                  error={!!error.email}
                />
              </RowLabelRenderer>
              {/*{!userEditMode && (
              <>
                <TextField
                  margin="dense"
                  name="password"
                  type="password"
                  fullWidth
                  variant="standard"
                  value={input.password}
                  readOnly
                  onChange={(e) => handleInputChange(e)}
                  label="Password"
                  helperText={error.password ? error.password : ""}
                  error={!!error.password}
                />
              </>
            )}*/}
              {fcGroup && (
                <>
                  <RowLabelRenderer label="Role">
                    <MDropdown
                      dropdownLabel="Select"
                      name="userGroupId"
                      handleChange={(e) => {
                        setInput({
                          ...input,
                          userGroupId: e.target.value,
                        });
                        validateInput("userGroupId", e.target.value);
                      }}
                      options={groups}
                      value={input?.userGroupId || ""}
                      error={!!error.userGroupId}
                      errorText={error.userGroupId}
                    />
                  </RowLabelRenderer>
                  {input.userGroupId === fcGroup.id && (
                    <RowLabelRenderer label="Organization">
                      <MDropdown
                        dropdownLabel="Select"
                        name="organizationId"
                        handleChange={(e) => {
                          setInput({
                            ...input,
                            organizationId: e.target.value,
                          });
                        }}
                        options={orgs}
                        value={input?.organizationId || ""}
                        //error={!!error.userGroups}
                        //errorText={error.userGroups}
                      />
                    </RowLabelRenderer>
                  )}
                  {input.id && (
                    <RowLabelRenderer label="Reset Password">
                      <ReconfirmBtn
                        btnTitle="Reset"
                        desc="Reset password of this account?"
                        handleClick={() => resetPassword(input.id)}
                        icon={<LockResetIcon />}
                      />
                    </RowLabelRenderer>
                  )}
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "flex-start" }}>
            <PrimaryBtn
              btnLabel="Confirm"
              handleClick={handleConfirm}
              disabled={
                !validateForm(
                  {
                    name: input.name,
                    email: input.email,
                    userGroupId: input.userGroupId,
                    organizationId:
                      input.userGroupId !== fcGroup.id
                        ? true
                        : input.organizationId,
                  },
                  error
                )
              }
            />
            <PrimaryBtn btnLabel="Cancel" handleClick={handleClose} />
          </DialogActions>
        </Dialog>
      </>
    )
  );
}
