import {Download} from "@mui/icons-material";
import {useAuth, withAdminAuthenticated, withAuthenticated,} from "../../lib/context/AuthContext";
import RfqsList from "../../components/RFQ/RfqsList";
import MPage from "../../components/common/MPage";
import MDialogRfq from "../../components/RFQ/MDialogRfq";
import {PrimaryIconBtn} from "../../components/common/Buttons";
import {useRfqs} from "../../lib/context/RfqsContext";
import useRfqInfo from "../../lib/hooks/useRfqInfo";

const RfqListPage = () => {
	const {isFC} = useAuth();
	const {handleExportRfqs} = useRfqs();
	const rfqInfoHook = useRfqInfo();

	return (
		<MPage
			title="RFQ"
			header={
				<>
					<PrimaryIconBtn
						btnLabel="Export"
						handleClick={handleExportRfqs}
						icon={<Download/>}
					/>
					{!isFC() && (
						<MDialogRfq
							buttonLabel="Create RFQ"
							title="Create RFQ"
							rfqInfoHook={rfqInfoHook}
						/>
					)}
				</>
			}
		>
			<RfqsList/>
		</MPage>
	);
};

export default withAuthenticated(withAdminAuthenticated(RfqListPage));
