import { axiosInstance } from "./base";

export const getRfqSubmission = (id) => {
  return axiosInstance.get(`/api/rfqSubmission/${id}`);
};
export const getRfqSubmissionSummary = (id) => {
  return axiosInstance.get(`/api/rfqSubmission/${id}/summary`);
};
export const getRfqSubmissionList = (
  rfqId,
  { q = null, s = null, n = null, order = null, filters = null }
) => {
  //return axiosInstance.get(`/api/rfqSubmission/${rfqId}/list`, {
  //  params: {
  //    q,
  //    s,
  //    n,
  //    order,
  //    filters,
  //  },
  //});
  return axiosInstance.get(`/api/rfqSubmission/${rfqId}/list`);
};
export const updateRfqSubmission = (newData) => {
  return axiosInstance.post(`/api/rfqSubmission`, newData);
};

export const declineRfqSubmission = (data) => {
  return axiosInstance.post(`api/rfqSubmission/decline`, data);
};

export const deleteRfqSubmission = (data) => {
  return axiosInstance.post(`api/rfqSubmission/delete`, data);
};

export const getLatestRfqSubmission = (rfqSupplierId) => {
  return axiosInstance.get(`/api/rfqSubmission/${rfqSupplierId}/latest`);
};

export const getRfqSupplierLatestPdf = (id) => {
  return axiosInstance.get(`/api/rfqSubmission/${id}/latestPdf`, {
    responseType: "blob",
  });
};
