import { useCallback, useState } from "react";

import { getAuditLog } from "../api/log";
import { useApi } from "../context/ApiContext";

export const useAuditLog = () => {
  const initTableConfig = {
    searchKeyword: "",
    startIndex: 0,
    rowsPerPage: 20,
    order: "desc",
    orderBy: "createdAt",
    filters: null,
    page: 0,
  };
  const api = useApi();
  const [tableConfig, setTableConfig] = useState(initTableConfig);
  const [auditLogs, setAuditLogs] = useState([]);
  const [auditLogsLength, setAuditLogsLength] = useState(0);

  const fetchAuditLog = useCallback(() => {
    const fetchingAuditLog = async () => {
      const response = await api.runApi(() =>
        getAuditLog({
          q: tableConfig.searchKeyword,
          s: tableConfig.startIndex,
          n: tableConfig.rowsPerPage,
          order: tableConfig.orderBy
            ? `${tableConfig.orderBy}:${tableConfig.order}`
            : "createdAt:desc",
        })
      );
      setAuditLogsLength(response?.data?.data?.totalLength);
      setAuditLogs(response?.data?.data?.auditLogList || []);
    };
    fetchingAuditLog();
  }, [tableConfig]);

  return {
    fetchAuditLog,
    auditLogs,
    auditLogsLength,
    tableConfig,
    setTableConfig,
    initTableConfig,
  };
};
