import { useState, useEffect } from "react";

import {
  AccountCircle,
  Email,
  Group,
  Logout,
  ManageAccounts,
  Person,
  SettingsApplications,
  ViewList,
} from "@mui/icons-material";
import { AppBar, Chip, Menu, MenuItem, Stack, Toolbar } from "@mui/material";
import { useAuth } from "../../lib/context/AuthContext";
import { Link } from "react-router-dom";
import { useUserContext } from "../../lib/context/userContext";
const navBarList = [
  {
    icon: <ViewList style={{ color: "#f1776c" }} />,
    title: "RFQ",
    subMenuItems: [],
    path: "/",
    isAdmin: false,
  },
  {
    icon: <SettingsApplications style={{ color: "#f1776c" }} />,
    title: "Manage",
    subMenuItems: [
      {
        icon: <Group style={{ color: "#f1776c" }} />,
        title: "Manage Organizations",
        path: "/user/organizations",
        isAdmin: true,
      },
      {
        icon: <Group style={{ color: "#f1776c" }} />,
        title: "Manage Groups",
        path: "/user/groups",
        isAdmin: true,
      },
      {
        icon: <Person style={{ color: "#f1776c" }} />,
        title: "Manage Users",
        path: "/user/users",
        isAdmin: true,
      },

      {
        icon: <ViewList style={{ color: "#f1776c" }} />,
        title: "Audit Log",
        path: "/system/auditLog",
        isAdmin: true,
      },
      {
        icon: <Email style={{ color: "#f1776c" }} />,
        title: "Email History",
        path: "/system/emailHistory",
        isAdmin: true,
      },
      {
        icon: <Email style={{ color: "#f1776c" }} />,
        title: "Email Templates",
        path: "/system/templates",
        isAdmin: true,
      },
      {
        icon: <Email style={{ color: "#f1776c" }} />,
        title: "SMTP Configuration",
        path: "/system/config/smtp",
        isAdmin: true,
      },
      {
        icon: <Email style={{ color: "#f1776c" }} />,
        title: "AdobeSign Configuration",
        path: "/system/config/adobeSign",
        isAdmin: true,
      },
    ],
    isAdmin: true,
  },
];

const NavBar = () => {
  const auth = useAuth();
  const { userInfo } = useUserContext();

  //useEffect(() => {
  //  fetchUserInfo();
  //}, [fetchUserInfo]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userEl, setUserEl] = useState(null);
  const handleLogout = () => {
    auth.logout();
  };

  const handleClick = (title, event) => {
    setAnchorEl({ [title]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      color="secondary"
      position="static"
      sx={{ background: "#fff", height: "80px" }}
    >
      <Toolbar sx={{ mx: 3 }}>
        <Stack
          sx={{
            flexGrow: 1,
            display: "inline-flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Link to={"/"}>
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "16px",
                marginRight: "40px",
              }}
            >
              <img
                src="/static/HarbourPlaza.png"
                width={120}
                height={64}
                alt={"Logo"}
              />
            </Stack>
          </Link>
          {auth.isAuthenticated &&
            !auth.isSupplier() &&
            navBarList.map((item) => {
              if (!item.isAdmin || (auth.isAdminRole() && !!item.isAdmin)) {
                return (
                  <Stack key={item.title}>
                    {item.subMenuItems.length === 0 && (
                      <Link to={item.path}>
                        <Chip icon={item.icon} label={item.title} />
                      </Link>
                    )}
                    {item.subMenuItems.length > 0 && (
                      <>
                        <Chip
                          icon={item.icon}
                          label={item.title}
                          onClick={(e) => handleClick(item.title, e)}
                          id="submenu"
                        />
                        <Menu
                          id="submenu"
                          anchorEl={anchorEl && anchorEl[item.title]}
                          open={Boolean(anchorEl && anchorEl[item.title])}
                          onClose={handleClose}
                        >
                          {item.subMenuItems.map((subMenuItem) => {
                            return (
                              (auth.isAdminRole() ? (
                                <Link
                                  to={subMenuItem.path}
                                  key={subMenuItem.title}
                                >
                                  <MenuItem onClick={handleClose}>
                                    <Chip
                                      icon={subMenuItem.icon}
                                      label={subMenuItem.title}
                                    />
                                  </MenuItem>
                                </Link>
                              ) : (
                                (auth.isPM() && !subMenuItem.isAdmin) ||
                                (auth.isFC() && !subMenuItem.isAdmin)
                              )) && (
                                <Link
                                  to={subMenuItem.path}
                                  key={subMenuItem.title}
                                >
                                  <MenuItem onClick={handleClose}>
                                    <Chip
                                      icon={subMenuItem.icon}
                                      label={subMenuItem.title}
                                    />
                                  </MenuItem>
                                </Link>
                              )
                            );
                          })}
                        </Menu>
                      </>
                    )}
                  </Stack>
                );
              } else {
                return "";
              }
            })}
        </Stack>
        {auth.isAuthenticated && !auth.isSupplier() && userInfo ? (
          <Stack>
            <Chip
              icon={<AccountCircle style={{ color: "#f1776c" }} />}
              label={
                <>
                  {userInfo.name}
                  <br />
                  <span>{userInfo.email}</span>
                </>
              }
              onClick={(e) => setUserEl(e.currentTarget)}
              className="avartar-chip"
            />

            <Menu
              anchorEl={userEl}
              open={Boolean(userEl)}
              onClose={() => setUserEl(false)}
            >
              <Link to="/user">
                <MenuItem>
                  <Chip
                    icon={<ManageAccounts style={{ color: "#f1776c" }} />}
                    label="Profile"
                  />
                </MenuItem>
              </Link>

              <MenuItem onClick={handleLogout}>
                <Chip
                  icon={<Logout style={{ color: "#f1776c" }} />}
                  label="Logout"
                />
              </MenuItem>
            </Menu>
          </Stack>
        ) : (
          <Stack>
            <Chip
              icon={<Logout style={{ color: "#f1776c" }} />}
              label="Logout"
              onClick={handleLogout}
            />
          </Stack>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
