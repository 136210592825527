import {
  useState,
  useCallback,
  useEffect,
  createContext,
  useContext,
} from "react";

import { useAuth } from "../context/AuthContext";
import { useApi } from "./ApiContext";
import { updateUser, changeMePassword, getMe } from "../api/user";
//import { SystemSecurityUpdateWarningSharp } from "@mui/icons-material";

export const userContext = createContext(null);

export const UserProvider = ({ children }) => {
  const api = useApi();
  const { user, init, isSupplier } = useAuth();
  const [userInfo, setUserInfo] = useState(null);

  const fetchUserInfo = useCallback(() => {
    const fetchingUserInfo = async () => {
      const response = await api.runApi(
        () => getMe(),
        (d) => d,
        {
          noWait: true,
          skip401Error: true,
        }
      );
      setUserInfo(response?.data?.data);
    };
    if (!isSupplier()) {
      console.log("fetch user");
      fetchingUserInfo();
    }
  }, []);

  const editHandler = async (newData) => {
    await api.runApi(() => updateUser(newData));
    fetchUserInfo();
  };

  const pwdChangeHandler = async (newData) => {
    await api.runApi(() => changeMePassword(newData));
    fetchUserInfo();
  };

  useEffect(() => {
    if (user && !init) {
      fetchUserInfo();
    }
  }, [fetchUserInfo, user, init]);

  return (
    <userContext.Provider
      value={{
        userInfo,
        setUserInfo,
        fetchUserInfo,
        editHandler,
        pwdChangeHandler,
      }}
    >
      {children}
    </userContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(userContext);
};
