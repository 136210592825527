import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 420,
      md: 1024,
      lg: 1440,
      xl: 1600,
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      '"Oxygen"',
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
    h1: {
      fontSize: "2.5rem",
    },
    button: {
      fontSize: "12px",
    },
  },
  palette: {
    background: {
      default: "#F4F8FB",
    },
    primary: {
      main: "#359DB4",
      light: "#5db0c3",
      dark: "#256d7d",
      contrastText: "#fff",
    },
    secondary: {
      main: "#F1776C",
      light: "#f39289",
      dark: "#a8534b",
      contrastText: "#fff",
    },
  },

  components: {
    //MuiButton: {
    //  variants: [
    //    {
    //      props: { variant: "outlined", color: "secondary" },
    //      style: {
    //        borderRadius: "0",
    //        textTransform: "inherit",
    //        border: "1px solid #e1dfe2",
    //        backgroundColor: "#fff",
    //        "&:hover": {
    //          border: "1px solid #4c4c4c",
    //          background: "#BABABA",
    //        },
    //      },
    //    },
    //    {
    //      props: { variant: "contained", color: "primary" },
    //      style: {
    //        borderRadius: "0",
    //        textTransform: "inherit",
    //        color: "#fff",
    //        border: "1px solid #D24D57",
    //        "&:hover": {
    //          background: "#BD454E",
    //        },
    //      },
    //    },
    //  ],
    //},
    //MuiTypography: {
    //  variants: [
    //    {
    //      props: {
    //        variant: "h1",
    //      },
    //      style: {
    //        color: "#333",
    //        fontWeight: "bold",
    //      },
    //    },
    //    {
    //      props: {
    //        variant: "span",
    //      },
    //      style: {
    //        color: "#fff",
    //        fontWeight: "bold",
    //        cursor: "pointer",
    //      },
    //    },
    //  ],
    //},
    //MuiTextField: {
    //  variants: [
    //    {
    //      props: { variant: "outlined" },
    //      style: {
    //        ".MuiOutlinedInput-root": {
    //          borderRadius: "0",
    //          background: "#fff",
    //        },
    //        "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {},
    //        "& .MuiOutlinedInput-notchedOutline": {
    //          border: "1px solid #e1dfe2",
    //        },
    //      },
    //    },
    //  ],
    //},
    //MuiInputLabel: {
    //  variants: [
    //    {
    //      props: {
    //        variant: "outlined",
    //      },
    //      style: {
    //        color: "#000",
    //      },
    //    },
    //  ],
    //},
    MuiSvgIcon: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            color: "#F1776C",
          },
        },
      ],
    },
  },
});
customTheme = responsiveFontSizes(customTheme);
export default customTheme;
