import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import { PrimaryBtn, PrimaryIconBtn } from "../common/Buttons";

const originError = {
  userGroups: false,
};

const FormDialog = ({
  buttonLabel,
  title,
  desc,
  btnHandler,
  data,
  icon = "",
}) => {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");
  const [error, setError] = useState(originError);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleInputChange = (e) => {
    setError(originError);

    setInput(e.target.value);
    if (!e.target.value.trim()) {
      setError({ [e.target.name]: "Please Enter Name" });
    }
  };
  const handleConfirm = () => {
    btnHandler(input, data);
    setOpen(false);
    setInput("");
  };
  const handleClose = () => {
    setOpen(false);
    setInput("");
    setError(originError);
  };

  return (
    <>
      <PrimaryIconBtn
        btnLabel={buttonLabel}
        handleClick={handleClickOpen}
        icon={icon}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{desc}</DialogContentText>
          <TextField
            margin="dense"
            id="name"
            name="userGroups"
            type="text"
            fullWidth
            variant="standard"
            value={input}
            onChange={(e) => handleInputChange(e)}
            //label="Group Name"
            helperText={error.userGroups ? error.userGroups : ""}
            error={!!error.userGroups}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start" }}>
          <PrimaryBtn
            btnLabel="Confirm"
            handleClick={handleConfirm}
            disabled={!input.trim()}
          />
          <PrimaryBtn btnLabel="Cancel" handleClick={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FormDialog;
