import { axiosInstance } from "./base";

export const getEmailTemplateBase = () => {
  return axiosInstance.get(`/api/notification/email/template/base`);
};

export const uploadEmailTemplateBase = (htmlData) => {
  return axiosInstance.post(`/api/notification/email/template/base`, {
    html: htmlData,
  });
};
export const deleteEmailTemplate = (id) => {
  return axiosInstance.delete(`/api/notification/email/template/${id}`);
};
export const getEmailTemplateTest = (recipient) => {
  return axiosInstance.post(`/api/notification/email/template/base/test`, {
    recipient,
  });
};

export const getEmailTemplates = () => {
  return axiosInstance.get(`/api/notification/email/template`);
};

export const testEmailTemplate = (id, recipient) => {
  return axiosInstance.post(`/api/notification/email/template/${id}/test`, {
    recipient,
  });
};

export const getEmailHistory = ({
  q = null,
  s = null,
  n = null,
  order = null,
  filters = null,
}) => {
  return axiosInstance.get(`/api/emailHistory`, {
    params: {
      q,
      s,
      n,
      order,
      filters,
    },
  });
};
export const sendEmailSupplierByIdAndRfqId = (
  supplierId,
  templateId,
  rfqId,
  recipient
) => {
  return axiosInstance.post(
    `/api/notification/email/template/sendEmail/${supplierId}`,
    {
      templateId,
      rfqId,
      recipient,
    }
  );
};

export const createEmailTemplate = (newData) => {
  return axiosInstance.post(`/api/notification/email/template`, newData);
};

export const updateEmailTemplate = (newData, id) => {
  return axiosInstance.post(`/api/notification/email/template/${id}`, newData);
};
