import { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { MInput } from "./MInputs";
import { RowLabelRenderer } from "./RowLabelRenderer";
import { PrimaryBtn } from "./Buttons";
import { validateForm } from "../../lib/utils/utils";

const originInput = {
  oldPassword: "",
  password: "",
  confirmPassword: "",
};

const originError = {
  oldPassword: false,
  password: false,
  confirmPassword: false,
};

export default function FormDialog({
  buttonLabel,
  title,
  desc = "",
  btnHandler,
  data,
}) {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(originInput);
  const [error, setError] = useState(originError);

  const validateInput = (name, value) => {
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };
      if (value.trim().length < 8) {
        stateObj[name] = "Minimum 8 characters";
      } else {
        switch (name) {
          case "password":
            if (!value) {
              stateObj[name] = "Please enter Password.";
            } else if (
              !value.match(/^(?=.*[0-9])(?=.*[A-Za-z])(?=.*[!@#$%^&*()\\-\\\\`{}:;'\",.?\\/~+=<>_]).{8,20}$/)
            ) {
              stateObj[name] =
                "Password must contain a length of at least 8 characters and a maximum of 20 characters, at least one digit [0-9], at least one Latin character [A-Za-z] and at least one special character.";
            } else if (
              input.confirmPassword &&
              value !== input.confirmPassword
            ) {
              stateObj["confirmPassword"] =
                "Password and Confirm Password does not match!";
            } else {
              stateObj["confirmPassword"] = input.confirmPassword
                ? ""
                : error.confirmPassword;
            }
            break;

          case "confirmPassword":
            if (!value) {
              stateObj[name] = "Please enter Confirm Password";
            } else if (input.password && value !== input.password) {
              stateObj[name] = "Password and Confirm Password does not match!";
            }
            break;
          default:
            break;
        }
      }

      return stateObj;
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
    validateInput(name, value);
  };

  const handleConfirm = () => {
    btnHandler({ ...input, id: data.id });
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setInput(originInput);
    setError(originError);
  };

  return (
    <>
      <PrimaryBtn btnLabel={buttonLabel} handleClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "0.7rem" }}>
            {desc}
          </DialogContentText>
          <Grid container rowSpacing={2} sx={{ margin: "auto" }}>            
            <RowLabelRenderer label="Old Password">
              <TextField
                margin="dense"
                id="oldPassword"
                name="oldPassword"
                type="password"
                fullWidth
                variant="filled"
                value={input.oldPassword}
                onChange={(e) => handleInputChange(e)}
                helperText={error.oldPassword ? error.oldPassword : ""}
                error={!!error.oldPassword}
              />
            </RowLabelRenderer>
            <RowLabelRenderer label="New Password">
              <TextField
                  margin="dense"
                  id="password"
                  name="password"
                  type="password"
                  fullWidth
                  variant="filled"
                  value={input.password}
                  onChange={(e) => handleInputChange(e)}
                  helperText={error.password ? error.password : ""}
                  error={!!error.password}
                />
            </RowLabelRenderer>
            <RowLabelRenderer label="Confirm New Password">
              <TextField
                margin="dense"
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                fullWidth
                variant="filled"
                value={input.confirmPassword}
                onChange={(e) => handleInputChange(e)}
                helperText={error.confirmPassword ? error.confirmPassword : ""}
                error={!!error.confirmPassword}
              />
            </RowLabelRenderer>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <PrimaryBtn
            btnLabel="Confirm"
            handleClick={handleConfirm}
            disabled={!validateForm(input, error)}
          />
          <PrimaryBtn btnLabel="Cancel" handleClick={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  );
}
