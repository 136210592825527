import { useEffect } from "react";
import {
  withAdminAuthenticated,
  withAuthenticated,
} from "../../lib/context/AuthContext";
import { useAuditLog } from "../../lib/hooks/useAuditLog";
import AuditLog from "../../components/auditLog";
import MPage from "../../components/common/MPage";
const AuditLogs = () => {
  const useAuditLogHooks = useAuditLog();
  const { fetchAuditLog } = useAuditLogHooks;
  useEffect(() => {
    fetchAuditLog();
  }, [fetchAuditLog]);

  return (
    <>
      <MPage title="Audit Log">
        <AuditLog useAuditLogHooks={useAuditLogHooks} />
      </MPage>
    </>
  );
};

export default withAuthenticated(withAdminAuthenticated(AuditLogs));
