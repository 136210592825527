import { useState, useCallback, createContext, useContext } from "react";
import { useApi } from "./ApiContext";
import { toast } from "react-toastify";

import {
  getAllRfqs,
  updateRfq,
  deleteRfq,
  createRfq,
  unLockRfqById,
  lockRfqById,
  exportRfqs,
  terminatedRFQById,
} from "../api/rfq";

import { useUserContext } from "../../lib/context/userContext";
import { useAuth } from "./AuthContext";
export const rfqsContext = createContext(null);

export const RfqsProvider = ({ children }) => {
  const initTableConfig = {
    searchKeyword: "",
    startIndex: 0,
    rowsPerPage: 20,
    order: "",
    orderBy: "",
    filters: { startTime: null, dueTime: null, state: "6" },
    page: 0,
  };
  //const auth = useAuth();
  const { user } = useAuth();
  const [tableConfig, setTableConfig] = useState(initTableConfig);
  const { userInfo } = useUserContext();
  const api = useApi();
  const [rfqs, setRfqs] = useState([]);
  const [rfqsLength, setRfqsLength] = useState(0);

  const fetchUnLockRfqById = async (rfqId, pwd) => {
    //console.log(rfqId, pwd);
    //const response = await api.runApi(() =>
    //  unlockAuth(rfqId, userInfo.email, pwd)
    //);
    //if (response?.data?.status) {
    //  const response = await api.runApi(() => unLockRfqById(rfqId));
    //  fetchRfqsList();
    //  return true;
    //}
    //return false;
    const response = await api.runApi(() => unLockRfqById(rfqId, pwd));
    fetchRfqsList();
  };

  const fetchLockRfqById = async (id) => {
    const response = await api.runApi(() => lockRfqById(id));
    fetchRfqsList();
  };
  const fetchTerminateRfqById = async (id) => {
    const response = await api.runApi(() => terminatedRFQById(id));
    fetchRfqsList();
  };
  const fetchRfqsList = useCallback(() => {
    const fetchingRfqsList = async () => {
      const fetchConfig = tableConfig;
      if (!fetchConfig.filters) {
        fetchConfig = {
          ...fetchConfig,
          filters: { startTime: null, dueTime: null, state: "6" },
        };
      }
      const filtersFormat = () => {
        const { state, startTime, dueTime } = fetchConfig.filters;
        let stateFilter = "";
        let timeFilter = "";
        if (state && state !== "6") {
          stateFilter = `state:${state}`;
        }

        if (startTime && dueTime && startTime < dueTime) {
          timeFilter = `startTime:${startTime.substring(
            0,
            10
          )}.dueTime:${dueTime.substring(0, 10)}`;
        } else if (startTime > dueTime) {
          toast.error("Please check selecting correct date.");
        }
        //console.log(startTime);
        if (stateFilter && timeFilter) {
          return stateFilter + "." + timeFilter;
        } else if (stateFilter && !timeFilter) {
          return stateFilter;
        } else if (!stateFilter && timeFilter) {
          return timeFilter;
        }
      };
      const response = await api.runApi(() =>
        getAllRfqs({
          q: fetchConfig.searchKeyword,
          s: fetchConfig.startIndex,
          n: fetchConfig.rowsPerPage,
          order: fetchConfig.orderBy
            ? `${fetchConfig.orderBy}:${fetchConfig.order}`
            : "createdAt:desc",
          filters: filtersFormat() ? filtersFormat() : "",
        })
      );

      setRfqs(response?.data?.data?.rfqList || []);
      setRfqsLength(response?.data?.data?.totalLength || 0);
    };
    fetchingRfqsList();
  }, [tableConfig]);

  const addBtnHandler = async (newRfqInput) => {
    const res = await api.runApi(() => createRfq({ ...newRfqInput }));
    fetchRfqsList();
    return res;
  };

  const deleteHandler = async (id) => {
    if (id) {
      await api.runApi(() => deleteRfq(id));
      fetchRfqsList();
    }
  };

  const editHandler = async (newData) => {
    const res = await api.runApi(() => updateRfq(newData));
    fetchRfqsList();
    return res;
  };
  const handleExportRfqs = async () => {
    const response = await exportRfqs();
    if (response.status === 200) {
      //const blob = new Blob([response.data], { type: "json" });
      //console.log("blob", blob);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      const fileName = decodeURI(
        response.headers["content-disposition"]
          .split(" ")[1]
          .replace("filename=", "")
          .replaceAll('"', "")
      );
      console.log(fileName);
      link.href = url;
      link.setAttribute("download", fileName);
      link.click();
    }
  };

  return (
    <rfqsContext.Provider
      value={{
        rfqs,
        rfqsLength,
        editHandler,
        deleteHandler,
        addBtnHandler,
        fetchRfqsList,
        fetchUnLockRfqById,
        fetchLockRfqById,
        //checkUnlockPermission,
        handleExportRfqs,
        tableConfig,
        setTableConfig,
        initTableConfig,
        fetchTerminateRfqById,
      }}
    >
      {children}
    </rfqsContext.Provider>
  );
};

export const useRfqs = () => {
  return useContext(rfqsContext);
};
