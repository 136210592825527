import { useState, useCallback, useEffect } from "react";

import {
  createEmailTemplate,
  getEmailTemplates,
  testEmailTemplate,
  updateEmailTemplate,
  deleteEmailTemplate,
} from "../api/notification";
import { useApi } from "../context/ApiContext";
import { useUserContext } from "../../lib/context/userContext";

export const useEmailTemplates = () => {
  const api = useApi();
  const { userInfo } = useUserContext();
  const [tableConfig, setTableConfig] = useState({
    searchKeyword: "",
    startIndex: 0,
    rowsPerPage: 20,
    order: "desc",
    orderBy: "createdAt",
    filters: null,
    page: 0,
  });
  const [templates, setTemplates] = useState([]);
  const [templatesLength, setTemplatesLength] = useState(0);

  const fetchEmailTemplate = useCallback(() => {
    const fetchingEmailTemplate = async () => {
      const response = await api.runApi(() => getEmailTemplates());
      setTemplates(response?.data?.data);
      setTemplatesLength((response?.data?.data).length);
    };
    fetchingEmailTemplate();
  }, []);

  const handleTestBtn = async (id) => {
    await api.runApi(() => testEmailTemplate(id, userInfo.email));
  };

  const addTemplateHandler = async (newData) => {
    await api.runApi(() => createEmailTemplate(newData));
    fetchEmailTemplate();
  };

  const editEmailTemplate = async (newData) => {
    await api.runApi(() => updateEmailTemplate(newData, newData.id));
    fetchEmailTemplate();
  };

  const fetchDeleteEmailTemplate = async (templateId) => {
    await api.runApi(() => deleteEmailTemplate(templateId));
    fetchEmailTemplate();
  };

  return {
    fetchEmailTemplate,
    templates,
    handleTestBtn,
    addTemplateHandler,
    editEmailTemplate,
    templatesLength,
    setTemplatesLength,
    tableConfig,
    setTableConfig,
    fetchDeleteEmailTemplate,
  };
};
