import { axiosInstance } from "./base";

export const getMe = () => {
  return axiosInstance.get(`/api/user/me`);
};

export const changeMePassword = (data) => {
  return axiosInstance.post(`/api/user/me/password`, {
    originalPassword: data.oldPassword,
    newPassword: data.password,
    confirmNewPassword: data.confirmPassword,
  });
};
export const getAllUsersAPI = ({
  q = null,
  s = null,
  n = null,
  order = null,
  filters = null,
}) => {
  return axiosInstance.get(`/api/user`, {
    params: {
      q,
      s,
      n,
      order,
      filters,
    },
  });
};

export const createUser = (newUserData) => {
  return axiosInstance.post(`/api/user`, {
    ...newUserData,
  });
};

export const updateUser = (newData) => {
  return axiosInstance.post(`/api/user/${newData.id}`, { ...newData });
};

export const deleteUserById = (id) => {
  return axiosInstance.delete(`/api/user/${id}`);
};

export const updateUserPassword = (id, data) => {
  return axiosInstance.post(`/api/user/${id}/password`, {
    originalPassword: data.oldPassword,
    newPassword: data.password,
    confirmNewPassword: data.confirmPassword,
  });
};
export const getUserGroups = () => {
  return axiosInstance.get(`/api/user/userGroup`);
};

//export const getUserById = (id) => {
//  return axiosInstance.get(`api/user/${id}`);
//};

export const resetUserPassword = (userId) => {
  return axiosInstance.post(`/api/user/resetPassword`, {
    id: userId,
  });
};
