import { useEffect } from "react";
import {
  withAdminAuthenticated,
  withAuthenticated,
} from "../../lib/context/AuthContext";

import MDialogInGroup from "../../components/Groups/MDialogInGroup";
import OrganizationList from "../../components/OrganizationsList";
import MPage from "../../components/common/MPage";
import useOrgs from "../../lib/hooks/useOrgs";

const Organizations = () => {
  const orgsHooks = useOrgs();
  const { fetchOrgs } = orgsHooks;
  useEffect(() => {
    console.log("fetch");
    fetchOrgs();
  }, [fetchOrgs]);

  return (
    <>
      <MPage
        title="Manage Organizations"
        header={
          <MDialogInGroup
            buttonLabel="Create Organization"
            title="Create Organization"
            desc=""
            btnHandler={orgsHooks.addBtnHandler}
          />
        }
      >
        <OrganizationList orgsHooks={orgsHooks} />
      </MPage>
    </>
  );
};

export default withAuthenticated(withAdminAuthenticated(Organizations));
