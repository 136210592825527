import { useLocation } from "react-router";
import { useMemo } from "react";
import { setMilliseconds, setMinutes, setSeconds } from "date-fns";

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateForm = (input, errors) => {
  let isError = false;
  for (let key in input) {
    if (!input[key] || errors[key]) {
      isError = true;
    }
  }

  return isError ? false : true;
};

export const createHeaders = (name, numeric = false, sortBy = null) => {
  return { name, numeric, sortBy };
};

export const isDefaultUserGroups = (groupsName) => {
  return (
    groupsName.toLowerCase() === "admin" ||
    groupsName.toLowerCase() === "user" ||
    groupsName.toLowerCase() === "supplier" ||
    groupsName.toLowerCase() === "root" ||
    groupsName.toLowerCase() === "purchasing manager" ||
    groupsName.toLowerCase() === "financial controller"
  );
};

export const checkState = (rfq) => {
  const { state, startTime, dueTime } = rfq;
  const now = new Date();
  let stateText = "";
  switch (state) {
    case 0:
      stateText = "Preparing";
      break;
    case 1:
      stateText = "Running";
      break;
    case 2:
      stateText = "Completed";
      break;
    case 3:
      stateText = "Unlocked";
      break;
    case 4:
      stateText = "Locked";
      break;
    case 5:
      stateText = "Terminated";
      break;
    default:
      break;
  }
  return stateText;
};

export const validDateTimeRange = (startTime, dueTime) => {
  let isValid = true;
  if (startTime && dueTime && dueTime < startTime) {
    isValid = false;
  }
  return isValid;
};

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const formatRfqDate = (dateValue) => {
  const newDateValueWithoutSeconds = setSeconds(new Date(dateValue), 0);
  const newDateValueWithoutMinutes = setMinutes(
    new Date(newDateValueWithoutSeconds),
    0
  );
  const result = setMilliseconds(newDateValueWithoutMinutes, 0);
  return result;
};
