import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Slide, ToastContainer } from "react-toastify";
import "./styles/globals.css";
import { ApiProvider } from "./lib/context/ApiContext";
import { AuthProvider } from "./lib/context/AuthContext";
import { GroupsProvider } from "./lib/context/GroupsContext";
import { RfqsProvider } from "./lib/context/RfqsContext";
import "react-toastify/dist/ReactToastify.css";
import { ConfigProvider } from "./lib/context/SmtpConfigContext";
import customTheme from "./styles/theme/customTheme";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/login";
import Logout from "./pages/logout";
import HomePage from "./pages";
import Users from "./pages/user/users";
import Organizations from "./pages/user/organizations";
import Groups from "./pages/user/groups";
import UserIndex from "./pages/user";
import EmailTemplates from "./pages/system/EmailTemplates";
import EmailHistory from "./pages/system/EmailHistory";
import AuditLog from "./pages/system/AuditLog";
import SmtpPage from "./pages/system/config/SmtpPage";
import SupplierLoginPage from "./pages/supplier";
import SubmissionIndex from "./pages/supplier/submission";
import SubmissionResultPage from "./pages/supplier/submission/results";
import ViewAttachmentPage from "./pages/rfq/viewAttachment";
import RfqListPage from "./pages/rfq";
import RfqDetailPage from "./pages/rfq/[RFQId]";
//import QuotationsPage from "./pages/rfq/[RFQId]/quotations";
import SummaryPage from "./pages/rfq/[RFQId]/summary";
import { AdobeSignProvider } from "./lib/context/AdobeSignContext";
import AdobeSignPage from "./pages/system/config/adobeSignPage";
import AdobeSignCode from "./pages/system/config/adobeSignCode";
import { UserProvider } from "./lib/context/userContext";

const PortalApp = () => {
  return (
    <>
      <Router>
        <ApiProvider>
          <AuthProvider>
            <UserProvider>
              <ConfigProvider>
                <AdobeSignProvider>
                  <GroupsProvider>
                    <RfqsProvider>
                      <ThemeProvider theme={customTheme}>
                        <CssBaseline />

                        <Routes>
                          <Route path={"/"} element={<HomePage />} />
                          <Route path={"/login"} element={<LoginPage />} />
                          <Route path={"/logout"} element={<Logout />} />

                          <Route
                            path={"/adobesign/auth/code"}
                            element={<AdobeSignCode />}
                          />

                          <Route path={"/rfq"} element={<RfqListPage />} />
                          <Route
                            path={"/rfq/:RFQId"}
                            element={<RfqDetailPage />}
                          />
                          {/*<Route
                            path={"/rfq/:RFQId/quotations"}
                            element={<QuotationsPage />}
                          />*/}
                          <Route
                            path={"/rfq/:RFQId/summary"}
                            element={<SummaryPage />}
                          />
                          <Route
                            path={"/rfq/viewAttachment"}
                            element={<ViewAttachmentPage />}
                          />

                          <Route
                            path={"/supplier"}
                            element={<SupplierLoginPage />}
                          />
                          <Route
                            path={"/supplier/submission"}
                            element={<SubmissionIndex />}
                          />
                          <Route
                            path={"/supplier/submission/results"}
                            element={<SubmissionResultPage />}
                          />

                          <Route
                            path={"/system/config/smtp"}
                            element={<SmtpPage />}
                          />
                          <Route
                            path={"/system/config/adobeSign"}
                            element={<AdobeSignPage />}
                          />
                          <Route
                            path={"/system/auditLog"}
                            element={<AuditLog />}
                          />
                          <Route
                            path={"/system/emailHistory"}
                            element={<EmailHistory />}
                          />
                          <Route
                            path={"/system/templates"}
                            element={<EmailTemplates />}
                          />

                          <Route path={"/user"} element={<UserIndex />} />
                          <Route path={"/user/groups"} element={<Groups />} />
                          <Route
                            path={"/user/organizations"}
                            element={<Organizations />}
                          />
                          <Route path={"/user/users"} element={<Users />} />
                        </Routes>
                      </ThemeProvider>
                    </RfqsProvider>
                  </GroupsProvider>
                </AdobeSignProvider>
              </ConfigProvider>
            </UserProvider>
          </AuthProvider>
        </ApiProvider>
      </Router>
      <ToastContainer
        limit={5}
        autoClose={8000}
        transition={Slide}
        position="top-right"
        hideProgressBar={false}
        closeOnClick
      />
    </>
  );
};

export default PortalApp;
