import { useEffect, useState } from "react";
import { PrimaryBtn, PrimaryIconBtn } from "../common/Buttons";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { validateForm } from "../../lib/utils/utils";
import { MInput, TextareaAutosizeSideLable } from "../common/MInputs";
import { RowLabelRenderer } from "../common/RowLabelRenderer";

const originError = {
  name: false,
  title: false,
  content: false,
};

const rfqContexts = [
  {
    id: "4afd-b869-02326497a9ea",
    name: "Name",
    content: "{rfq.name}",
  },
  {
    id: "4afd-b869-02326497a9eb",
    name: "Reference No.",
    content: "{rfq.referenceNumber}",
  },
  {
    id: "4afd-b869-02326497a9ec",
    name: "Due Date",
    content: "{rfq.dueDate}",
  },
  {
    id: "4afd-b869-02326497a9ed",
    name: "Due Time",
    content: "{rfq.dueTime}",
  },
  {
    id: "4afd-b869-02326497a9ee",
    name: "WebformUrl",
    content: "{rfq.webformUrl}",
  },
  {
    id: "4afd-b869-02326497a9ef",
    name: "Login Token",
    content: "{rfq.loginToken}",
  },

  {
    id: "4afd-b869-02326497a9eg",
    name: "New Line",
    content: "\\r\\n",
  },
];
const transformToHTML = (content) => {
  return content.replaceAll("\\r\\n", "<br/>");
};
export default function FormDialog({
  buttonLabel,
  title,
  desc = "",
  btnHandler,
  data,
  icon,
  buttonWidth,
}) {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(data);
  const [error, setError] = useState(originError);

  const validateInput = (name, value) => {
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "name":
          if (!value) {
            stateObj[name] = "Please enter Username";
          }
          break;
        case "title":
          if (!value) {
            stateObj[name] = "Please enter Title";
          }
          break;
        case "content":
          if (!value) {
            stateObj[name] = "Please enter Content";
          }
          break;
        default:
          break;
      }
      return stateObj;
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleRfqContext = (content, e) => {
    let temp;
    setInput((prev) => {
      temp = prev.content;
      return {
        ...prev,
        content: temp + `${content}`,
      };
    });
    validateInput("content", temp + `${content}`);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInput({
      ...input,
      [name]: value,
    });
    validateInput(name, value);
  };

  const handleConfirm = () => {
    btnHandler({
      ...input,
      content: input.content.replaceAll("\\r\\n", "\r\n"),
    });
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setInput(data);
    setError(originError);
  };
  useEffect(() => {
    setInput(data);
  }, [data]);
  return (
    <>
      <PrimaryIconBtn
        btnLabel={buttonLabel}
        handleClick={handleClickOpen}
        icon={icon}
        customWidth={buttonWidth}
      />
      <Dialog open={open} onClose={handleClose} maxWidth="600px" widtg="600px">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{desc}</DialogContentText>
          <Grid container rowSpacing={2} sx={{ margin: "auto" }}>
            <RowLabelRenderer label="Name">
              <MInput
                name="name"
                value={input.name}
                handleChange={(e) => handleInputChange(e)}
                readOnly={false}
                errorText={error.name ? error.name : ""}
                error={!!error.name}
              />
            </RowLabelRenderer>
            <RowLabelRenderer label="Subject">
              <MInput
                name="title"
                value={input.title}
                handleChange={(e) => handleInputChange(e)}
                readOnly={false}
                errorText={error.title ? error.title : ""}
                error={!!error.title}
              />
            </RowLabelRenderer>
            <RowLabelRenderer label="RFQ Context">
              <Grid container spacing={1}>
                {rfqContexts.map((item) => {
                  return (
                    <Grid item>
                      <PrimaryBtn
                        key={item.id}
                        btnLabel={item.name}
                        handleClick={(event) =>
                          handleRfqContext(item.content, event)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </RowLabelRenderer>
            <RowLabelRenderer label="Content">
              <TextareaAutosizeSideLable
                name="content"
                value={input.content}
                handleChange={(e) => handleInputChange(e)}
                error={!!error.content}
                errorText={error.content ? error.content : ""}
                maxRows={16}
              />
            </RowLabelRenderer>
            <RowLabelRenderer label="Preview">
              <div
                className="email-template-content"
                dangerouslySetInnerHTML={{
                  __html: transformToHTML(input.content),
                }}
              />
            </RowLabelRenderer>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <PrimaryBtn
            btnLabel="Confirm"
            handleClick={handleConfirm}
            disabled={
              !validateForm(
                {
                  name: input.name,
                  title: input.title,
                  content: input.content,
                },
                error
              )
            }
          />
          <PrimaryBtn btnLabel="Cancel" handleClick={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  );
}
