import { Box } from "@mui/material";
import MDialogInGroup from "./Groups/MDialogInGroup";
import { ReconfirmBtn } from "./common/Buttons";
import { createHeaders, isDefaultUserGroups } from "../lib/utils/utils";
import MTable, { StyledTableCell, StyledTableRow } from "./common/MTable";
const headers = [
  createHeaders("Organization Name", false, "name"),
  createHeaders("Action", false, null),
];
const OrganizationList = ({ orgsHooks }) => {
  const {
    orgs,
    orgsLength,
    deleteHandler,
    editHandler,
    tableConfig,
    setTableConfig,
  } = orgsHooks;
  return (
    <>
      <Box sx={{ maxWidth: "1240px", margin: "0 auto" }}>
        {orgs && (
          <MTable
            tableConfig={tableConfig}
            setTableConfig={setTableConfig}
            search={true}
            headers={headers}
            records={orgs}
            totalLength={orgsLength}
            rowRenderer={(record) => (
              <StyledTableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={record.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row" padding="none">
                  {record.name}
                </StyledTableCell>
                {isDefaultUserGroups(record.name) ? (
                  <>
                    <StyledTableCell align="center"></StyledTableCell>
                  </>
                ) : (
                  <>
                    <StyledTableCell align="center">
                      <MDialogInGroup
                        buttonLabel="Edit"
                        title="Rename Organization Name"
                        btnHandler={editHandler}
                        data={{ id: record.id, name: record.name }}
                      />

                      <ReconfirmBtn
                        btnTitle="Delete"
                        desc="Delete this data?"
                        handleClick={() => deleteHandler(record.id)}
                      />
                    </StyledTableCell>
                  </>
                )}
              </StyledTableRow>
            )}
          />
        )}
      </Box>
    </>
  );
};

export default OrganizationList;
