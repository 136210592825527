import { useEffect } from "react"
import { Download } from "@mui/icons-material"
import {
  ArrowBackIos,
  NavigateNext,
  NavigateBefore,
  FirstPage,
  LastPage,
} from "@mui/icons-material"
import { Document, Page, pdfjs } from "react-pdf"
import { Box, IconButton, Stack, Typography } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import { useNavigate } from "react-router"

import { PrimaryIconBtn } from "../../components/common/Buttons"
import MPage from "../../components/common/MPage"
import useAttachment from "../../lib/hooks/useAttachment"
//import { useQuery } from "../../lib/utils/utils";

const ViewAttachmentPage = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const fileId = params.get("rfqFileId")
  const lastPdfUrl = params.get("pdfUrl")

  const pageTitle = params.get("pageTitle")
  let fileName = ""
  if (!!lastPdfUrl) {
    fileName = params.get("fileName")
  }
  const {
    setNumPages,
    setPageNumber,
    numPages,
    pdfUrl,
    pageNumber,
    fetchFilePdf,
  } = useAttachment(fileId)

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  if (fileId) {
    document.addEventListener("contextmenu", (event) => {
      event.preventDefault()
    })
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function goToFirstPage() {
    setPageNumber(1)
  }

  function gotToLastPage() {
    setPageNumber(numPages)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  useEffect(() => {
    if (fileId) {
      fetchFilePdf()
    }
  }, [fetchFilePdf])

  const handleDownloadPdf = () => {
    const linkAnchor = document.createElement("a")
    linkAnchor.href = lastPdfUrl
    linkAnchor.download = fileName
    linkAnchor.click()
  }

  return (
    <>
      <MPage
        title={pageTitle}
        header={
          <div>
            {!!lastPdfUrl && (
              <PrimaryIconBtn
                btnLabel="Download"
                handleClick={handleDownloadPdf}
                icon={<Download />}
              />
            )}

            <IconButton
              size="medium"
              //sx={{ color: "#359DB4" }}
              color="primary"
              edge={false}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIos fontSize="inherit" />
            </IconButton>
          </div>
        }
      >
        <div className="main">
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              flexFlow: "column",
              alignItems: "center",
            }}
            sx={{ maxWidth: "1240px", margin: "auto", padding: "20px" }}
          >
            <Document
              file={pdfUrl || lastPdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<div>Loading...</div>}
              noData={fileId ? <div>Please Wait...</div> : <></>}
              //onLoadError={(error) =>
              //  alert("Error while loading document! " + error.message)
              //}
              //onSourceError={(err) => console.log(err)}
            >
              <Page pageNumber={pageNumber} />
            </Document>

            {numPages && (
              <Stack
                style={{
                  display: "flex",
                  flexFlow: "row",
                  marginTop: "20px",
                  alignItems: "center",
                }}
              >
                <IconButton
                  size="small"
                  sx={{ marginRight: "8px" }}
                  color="primary"
                  edge={false}
                  onClick={goToFirstPage}
                  disabled={pageNumber <= 1}
                >
                  <FirstPage fontSize="inherit" />
                </IconButton>
                <IconButton
                  size="small"
                  //sx={{ color: "#359DB4" }}
                  color="primary"
                  edge={false}
                  onClick={previousPage}
                  disabled={pageNumber <= 1 || pageNumber === 1}
                >
                  <NavigateBefore fontSize="inherit" />
                </IconButton>
                <Typography fontSize="inherit">
                  {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}
                </Typography>
                <IconButton
                  size="small"
                  //sx={{ color: "#359DB4" }}
                  color="primary"
                  edge={false}
                  onClick={nextPage}
                  disabled={pageNumber >= numPages}
                >
                  <NavigateNext fontSize="inherit" />
                </IconButton>
                <IconButton
                  size="small"
                  sx={{ marginLeft: "8px" }}
                  color="primary"
                  edge={false}
                  onClick={gotToLastPage}
                  disabled={pageNumber === numPages}
                >
                  <LastPage fontSize="inherit" />
                </IconButton>
              </Stack>
            )}
          </Box>
        </div>
      </MPage>
    </>
  )
}
export default ViewAttachmentPage
