import { useEffect } from "react";
import { Box, Divider } from "@mui/material";

import useRfqSupplier from "../../../lib/hooks/useRfqSupplier";
import PageHeader from "../../common/PageHeader";
import SuppliersLists from "./SuppliersList";
import MDialogInSupplier from "./MDialogInSupplier";
import { useAuth } from "../../../lib/context/AuthContext";
import { useEmailTemplates } from "../../../lib/hooks/useEmailTemplates";
const Suppliers = ({ rfqSupplierHooks, rfq }) => {
  const { isFC, isPM } = useAuth();
  const { addBtnHandler } = rfqSupplierHooks;
  const { templates, fetchEmailTemplate } = useEmailTemplates();
  useEffect(() => {
    fetchEmailTemplate();
  }, [fetchEmailTemplate]);
  return (
    <>
      <Box
        style={{ display: "flex", justifyContent: "space-between" }}
        sx={{ maxWidth: "1240px", margin: "120px auto 20px" }}
      >
        <PageHeader title="Supplier" />
        {rfq.state === 0 && !isFC() && (
          <MDialogInSupplier
            buttonLabel="Create Supplier"
            title="Create Supplier"
            btnHandler={addBtnHandler}
            data={{
              companyName: "",
              emailAddress: "",
            }}
            customWidth="160px"
          />
        )}
      </Box>
      <Divider />
      {templates && (
        <SuppliersLists
          rfqSupplierHooks={rfqSupplierHooks}
          rfq={rfq}
          templates={templates}
        />
      )}
    </>
  );
};

export default Suppliers;
