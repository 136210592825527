import { axiosInstance } from "./base";

export const getAuditLog = ({
  q = null,
  s = null,
  n = null,
  order = null,
  filters = null,
}) => {
  return axiosInstance.get(`/api/auditLog`, {
    params: {
      q,
      s,
      n,
      order,
      filters,
    },
  });
};
