import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";

import {
  withAdminAuthenticated,
  withAuthenticated,
} from "../../../lib/context/AuthContext";
import RenderRfqInfo from "../../../components/RFQ/RenderRfqInfo";
import Suppliers from "../../../components/RFQ/supplier";
import MPage from "../../../components/common/MPage";
import useRfqInfo from "../../../lib/hooks/useRfqInfo";
import useRfqSupplier from "../../../lib/hooks/useRfqSupplier";

const RfqDetailPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [isReadMode, setIsReadMode] = useState(true);
  const { RFQId } = params;
  const rfqInfoHook = useRfqInfo(isReadMode);
  const rfqSupplierHooks = useRfqSupplier({ rfqId: RFQId });
  const { fetchRfqSupplierList, rfqSuppliersList } = rfqSupplierHooks;
  const { rfq, fetchRfq } = rfqInfoHook;
  useEffect(() => {
    if (RFQId) {
      fetchRfq(RFQId);
      fetchRfqSupplierList();
    }
  }, [RFQId, fetchRfq, isReadMode, fetchRfqSupplierList]);
  useEffect(() => {
    if (rfq && rfq.state === 5) {
      navigate(-1);
    }
  }, [rfq, navigate]);
  return (
    <>
      <MPage
        title="RFQ Detail"
        header={
          <div>
            <IconButton
              size="medium"
              //sx={{ color: "#359DB4" }}
              color="primary"
              edge={false}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIos fontSize="inherit" />
            </IconButton>
          </div>
        }
      >
        {rfq && rfq.state !== 5 && rfqSuppliersList && (
          <>
            <RenderRfqInfo
              isReadMode={isReadMode}
              rfqInfoHook={rfqInfoHook}
              toggleIsReadMode={() => setIsReadMode(!isReadMode)}
            />
            <Suppliers rfqSupplierHooks={rfqSupplierHooks} rfq={rfq} />
          </>
        )}
      </MPage>
    </>
  );
};

export default withAuthenticated(withAdminAuthenticated(RfqDetailPage));
