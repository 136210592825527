import { useEffect, useState } from "react"
import { format } from "date-fns"
import { Box, Grid, CircularProgress, Typography } from "@mui/material"
import { Link as LinkIcon } from "@mui/icons-material"

import { MInput } from "../common/MInputs"
import { PrimaryIconBtn, ReconfirmBtn } from "../common/Buttons"
import { RowLabelRenderer } from "../common/RowLabelRenderer"
import useRfqInfo from "../../lib/hooks/useRfqInfo"
import { useAuth } from "../../lib/context/AuthContext"
import { useNavigate } from "react-router"
const checkState = (state) => {
  let stateTxt = ""
  switch (state) {
    case 0:
      stateTxt = "View"
      break
    case 1:
      stateTxt = "View"
      break
    case 2:
      stateTxt = "Deleted"
      break
    case 3:
      stateTxt = "Declined"
      break
    default:
      stateTxt = "Not Submitted"
      break
  }
  return stateTxt
}
const checkAllowView = (lastSubmittedState, rfqState) => {
  return (
    rfqState !== 5 && (lastSubmittedState === 0 || lastSubmittedState === 1)
  )
}
const Submission = ({ useSupplierHook }) => {
  const navigate = useNavigate()
  const {
    supplierInfo,
    adobeSignUrl,
    fetchAdobeSignUrl,
    fetchDeclineRfqSubmission,
    fetchDeleteRfqSubmission,
    latestRfqSubmission,
    fetchSupplierLatestPdf,
    isLoading,
  } = useSupplierHook
  const { fetchRfq, rfq } = useRfqInfo()

  useEffect(() => {
    fetchRfq(supplierInfo.rfqId)
  }, [fetchRfq])

  const handleSubmit = async () => {
    const urlResp = await fetchAdobeSignUrl()
    if (!urlResp) {
      return navigate(`/supplier/submission/results?mode=error`)
    }
    window.location.href = urlResp
  }
  const handleDecline = async () => {
    const response = await fetchDeclineRfqSubmission()
    if (!response) {
      return navigate(`/supplier/submission/results?mode=error`)
    }
    navigate(`/supplier/submission/results?mode=decline`)
  }
  const handleDelete = async () => {
    const response = await fetchDeleteRfqSubmission()
    if (!response) {
      return navigate(`/supplier/submission/results?mode=error`)
    }
    navigate(`/supplier/submission/results?mode=delete`)
  }

  const SubmissionActionRenderer = () => {
    return (
      <Box
        sx={{
          maxWidth: "1240px",
          margin: "40px 0",
          display: "flex",
        }}
      >
        {rfq?.state === 1 && (
          <>
            <ReconfirmBtn
              btnTitle="Go To Submit"
              desc="You will be redirected to the AdobeSign website."
              handleClick={handleSubmit}
              icon={<></>}
              color="primary"
              //customWidth="120px"
              disabled={latestRfqSubmission?.state === 0}
            />
            <ReconfirmBtn
              btnTitle="Declined to Offer"
              desc="Do you confirm to decline to offer?"
              handleClick={handleDecline}
              icon={<></>}
              color="primary"
              //customWidth="120px"
              disabled={latestRfqSubmission?.state === 3}
            />
            {latestRfqSubmission?.state === 0 && (
              <ReconfirmBtn
                btnTitle="Delete"
                desc="Do you want to Delete previous quotations?"
                handleClick={handleDelete}
                icon={<></>}
                color="primary"
                //customWidth="120px"
              />
            )}
          </>
        )}
        {rfq?.state === 0 && (
          <Typography
            sx={{
              //my: 5,
              color: "#f1776c",
              fontSize: "18px",
              fontWeight: "bold",
              //margin: "20px",
            }}
            variant="p"
          >
            This RFQ has not started yet, no submission is allowed.
          </Typography>
        )}
        {(rfq?.state === 2 || rfq?.state === 3) && (
          <Typography
            sx={{
              //my: 5,
              color: "#f1776c",
              fontSize: "18px",
              fontWeight: "bold",
              //margin: "20px",
            }}
            variant="p"
          >
            This RFQ has ended, no submission is allowed.
          </Typography>
        )}
        {rfq?.state === 4 && (
          <Typography
            sx={{
              //my: 5,
              color: "#f1776c",
              fontSize: "18px",
              fontWeight: "bold",
              //margin: "20px",
            }}
            variant="p"
          >
            This RFQ has been locked.
          </Typography>
        )}
        {rfq?.state === 5 && (
          <Typography
            sx={{
              //my: 5,
              color: "#f1776c",
              fontSize: "18px",
              fontWeight: "bold",
              //margin: "20px",
            }}
            variant="p"
          >
            This RFQ has been terminated, no submission is allowed.
          </Typography>
        )}
      </Box>
    )
  }
  const LastSubmittedRenderer = () => {
    return (
      <RowLabelRenderer label="Last Submitted">
        <PrimaryIconBtn
          btnLabel={checkState(latestRfqSubmission?.state)}
          handleClick={async () => {
            const pdfResp = await fetchSupplierLatestPdf(supplierInfo.id)
            if (pdfResp.status === 200) {
              const fileName = `${rfq.name}-${supplierInfo.companyName}-quotation.pdf`
              return navigate(
                `/rfq/viewAttachment?pageTitle=Submitted Quotation&pdfUrl=${URL.createObjectURL(
                  pdfResp.data
                )}&fileName=${fileName}`
              )
            }
          }}
          icon={
            checkAllowView(latestRfqSubmission?.state, rfq.state) ? (
              <LinkIcon />
            ) : (
              ""
            )
          }
          disabled={!checkAllowView(latestRfqSubmission?.state, rfq.state)}
          customWidth="120px"
        />
      </RowLabelRenderer>
    )
  }
  return (
    <>
      <Box
        className="rfq-info-wrapper"
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
        sx={{ maxWidth: "1240px", margin: "0" }}
      >
        {isLoading && (
          <>
            <Typography
              sx={{
                my: 5,
                color: "#2D3035",
                fontSize: "18px",
                fontWeight: "bold",
                margin: "20px",
              }}
              variant="p"
            >
              Generating submission form on Adobe Sign ...
            </Typography>
            <CircularProgress sx={{ margin: "20px", alignSelf: "center" }} />
          </>
        )}
        {rfq && !isLoading && (
          <>
            <Grid
              container
              rowSpacing={1}
              sx={{ maxWidth: "1240px", margin: "20px auto" }}
            >
              <RowLabelRenderer label="Name">
                <MInput name="name" value={rfq.name} readOnly={true} />
              </RowLabelRenderer>
              <RowLabelRenderer label="Reference Number">
                <MInput
                  name="referenceNumber"
                  value={rfq.referenceNumber}
                  readOnly={true}
                />
              </RowLabelRenderer>
              <RowLabelRenderer label="Site Applied">
                <MInput
                  name="siteApplied"
                  value={rfq.organization.name}
                  readOnly={true}
                />
              </RowLabelRenderer>
              <RowLabelRenderer label="RFQ Start Date">
                <MInput
                  name="startTime"
                  value={format(new Date(rfq.startTime), "dd/MM/yyyy HH:mm")}
                  readOnly={true}
                />
              </RowLabelRenderer>
              <RowLabelRenderer label="RFQ End Date">
                <MInput
                  name="dueTime"
                  value={format(new Date(rfq.dueTime), "dd/MM/yyyy HH:mm")}
                  readOnly={true}
                />
              </RowLabelRenderer>
              <RowLabelRenderer label="Company Name">
                <MInput
                  name="companyName"
                  value={supplierInfo.companyName}
                  readOnly={true}
                />
              </RowLabelRenderer>
              <RowLabelRenderer label="Company Email">
                <MInput
                  name="email"
                  value={supplierInfo.emailAddress}
                  readOnly={true}
                  type="email"
                />
              </RowLabelRenderer>
              <RowLabelRenderer label="RFQ Terms &amp; Conditions">
                <PrimaryIconBtn
                  btnLabel="View "
                  handleClick={() =>
                    navigate(
                      `/rfq/viewAttachment?pageTitle=RFQ%20Terms%20%26%20Conditions&rfqFileId=${rfq?.fileId}`
                    )
                  }
                  icon={<LinkIcon />}
                  disabled={!rfq.fileId || rfq.state === 5}
                  customWidth="120px"
                />
              </RowLabelRenderer>
              {rfq?.state !== 0 && <LastSubmittedRenderer />}
            </Grid>
            <SubmissionActionRenderer />
          </>
        )}
      </Box>
    </>
  )
}

export default Submission
