import {withAdminAuthenticated, withAuthenticated,} from "../../lib/context/AuthContext";
import GroupsList from "../../components/Groups/GroupsList";
import MPage from "../../components/common/MPage";

const Groups = () => {
	return (
		<>
			<MPage
				title="Manage Groups"
				//header={
				//  <MDialogInGroup
				//    buttonLabel="Create Group"
				//    title="Create Group"
				//    desc=""
				//    btnHandler={addBtnHandler}
				//    data={""}
				//  />
				//}
			>
				<GroupsList/>
			</MPage>
		</>
	);
};

export default withAuthenticated(withAdminAuthenticated(Groups));
