import { axiosInstance } from "./base";

export const loginApi = (email, password) =>
  axiosInstance.post("/api/auth/login", {
    email,
    password,
  });

export const verifyLoginAPI = () => axiosInstance.get("/api/auth/verify");

export const loginSupplierAPI = (token) =>
  axiosInstance.post("/api/auth/supplier", {
    token,
  });
