import { useEffect, useState, useCallback } from "react";
import {
  getRfqSupplierLatestPdf,
  getRfqSubmissionSummary,
} from "../../lib/api/rfqSubmission";
//import { getAgreementPdf } from "../api/adobeSignWidget";
import { useApi } from "../context/ApiContext";
export default function useRfqSummary({ rfqId }) {
  const api = useApi();
  const [rfqSubmissionList, setRfqSubmissionList] = useState([]);
  const [rfqSubmissionLength, setRfqSubmissionLength] = useState(0);
  const [rfqReturnBids, setRfqReturnBids] = useState(null);
  const [tableConfig, setTableConfig] = useState({
    searchKeyword: "",
    startIndex: 0,
    rowsPerPage: 20,
    order: "",
    orderBy: "",
    filters: null,
    page: 0,
  });
  const fetchRfqSubmissionSummary = useCallback(() => {
    const fetchingRfqSubmissionSummary = async () => {
      const response = await api.runApi(() => getRfqSubmissionSummary(rfqId));
      setRfqSubmissionList(response?.data?.data?.rfqSubmissionList || []);
      setRfqSubmissionLength(response?.data?.data?.totalLength || 0);
      setRfqReturnBids(response?.data?.data?.returnBids || 0);
    };
    fetchingRfqSubmissionSummary();
  }, [tableConfig]);

  const fetchSupplierLatestPdf = async (supplierId) => {
    const response = await getRfqSupplierLatestPdf(supplierId);
    return response;
  };
  return {
    rfqSubmissionList,
    rfqSubmissionLength,
    tableConfig,
    setTableConfig,
    fetchRfqSubmissionSummary,
    fetchSupplierLatestPdf,
    rfqReturnBids,
  };
}
