import {Grid} from "@mui/material";
import {MInputLabel} from "./MInputs";

export const RowLabelRenderer = ({
																	 label,
																	 children,
																	 labelColumn = 4,
																	 InputColumn = 8,
																 }) => {
	return (
		<>
			<Grid item xs={labelColumn} sx={{alignSelf: "center"}}>
				<MInputLabel label={label}/>
			</Grid>
			<Grid item xs={InputColumn}>
				{children}
			</Grid>
		</>
	);
};
