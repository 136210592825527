import { useCallback, useState } from "react";
import { useApi } from "../context/ApiContext";
import {
  createUser,
  deleteUserById,
  getAllUsersAPI,
  getUserGroups,
  resetUserPassword,
  updateUser,
  updateUserPassword,
} from "../api/user";
import { getAllOrg } from "../api/organization";

export const useUsers = () => {
  const api = useApi();
  const [tableConfig, setTableConfig] = useState({
    searchKeyword: "",
    startIndex: 0,
    rowsPerPage: 20,
    order: "",
    orderBy: "",
    filters: null,
    page: 0,
  });
  const [users, setUsers] = useState(null);
  const [usersLength, setUsersLength] = useState(0);
  const [groups, setGroups] = useState(null);
  const [orgs, setOrgs] = useState(null);

  const fetchOrgs = useCallback(() => {
    const fetcingOrgs = async () => {
      const response = await api.runApi(() =>
        getAllOrg({
          q: "",
          s: "",
          n: "",
          order: tableConfig.orderBy
            ? `${tableConfig.orderBy}:${tableConfig.order}`
            : "createdAt:desc",
        })
      );
      console.log(response);
      setOrgs(response?.data?.data?.organizationList);
    };
    fetcingOrgs();
  }, []);

  const fetchGroups = useCallback(() => {
    const fetchingGroups = async () => {
      const response = await api.runApi(() => getUserGroups());
      setGroups(response?.data?.data);
    };
    fetchingGroups();
  }, []);
  const fetchUsers = useCallback(() => {
    const fetchingUsers = async () => {
      const response = await api.runApi(() =>
        getAllUsersAPI({
          q: tableConfig.searchKeyword,
          s: tableConfig.startIndex,
          n: tableConfig.rowsPerPage,
          order: tableConfig.orderBy
            ? `${tableConfig.orderBy}:${tableConfig.order}`
            : "createdAt:desc",
        })
      );
      //console.log(response?.data?.data?.userList);
      setUsers(response?.data?.data?.userList || []);
      setUsersLength(response?.data?.data?.totalLength);
      fetchGroups();
      fetchOrgs();
    };
    fetchingUsers();
  }, [tableConfig]);
  const addBtnHandler = async (newUserInput) => {
    await api.runApi(() => createUser(newUserInput));
    fetchUsers();
  };
  const deleteHandler = async (id) => {
    if (id) {
      await api.runApi(() => deleteUserById(id));
      fetchUsers();
    }
  };
  const resetPassword = async (id) => {
    if (id) {
      await api.runApi(() => resetUserPassword(id));
    }
  };
  const pwdHandler = async (id, newData) => {
    await api.runApi(() => updateUserPassword(id, newData));
    fetchUsers();
  };

  const editHandler = async (newData) => {
    await api.runApi(() => updateUser(newData));
    fetchUsers();
  };

  //useEffect(() => {
  //  fetchGroups();
  //  fetchOrgs();
  //}, [fetchGroups, fetchOrgs]);

  return {
    tableConfig,
    setTableConfig,
    users,
    usersLength,
    fetchUsers,
    addBtnHandler,
    deleteHandler,
    pwdHandler,
    editHandler,
    groups,
    orgs,
    resetPassword,
  };
};
