import {createContext, useContext} from "react";
import {useApi} from "./ApiContext";
import {getSmtpConfigAPI, sendTestSmtpAPI, updateSmtpConfigAPI} from "../api/smtp";

const smtpConfigContext = createContext(null)

export const ConfigProvider = ({children}) => {
	const api = useApi()

	const getSmtpConfig = () => api.runApi(() => getSmtpConfigAPI())

	const updateSmtpConfig = (smtpConfig) => api.runApi(() => updateSmtpConfigAPI(smtpConfig))

	const sendTestSmtp = (recipient) => api.runApi(() => sendTestSmtpAPI(recipient))

	return (
		<smtpConfigContext.Provider value={{
			getSmtpConfig,
			updateSmtpConfig,
			sendTestSmtp,
		}}>
			{children}
		</smtpConfigContext.Provider>
	)
}

export const useSmtpConfig = () => useContext(smtpConfigContext)
