import { useEffect } from "react";
import { Box } from "@mui/material";

import MTable, { StyledTableCell, StyledTableRow } from "../common/MTable";
import MDialogInGroup from "./MDialogInGroup";
import { ReconfirmBtn } from "../common/Buttons";
import { createHeaders, isDefaultUserGroups } from "../../lib/utils/utils";
import { useGroups } from "../../lib/context/GroupsContext";

const headers = [
  createHeaders("Group Name", false, "name"),
  createHeaders("Action", false, null),
];
const Groups = () => {
  const {
    groups,
    deleteHandler,
    editHandler,
    fetchGroups,
    groupsLength,
    setTableConfig,
    tableConfig,
  } = useGroups();
  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  return (
    <>
      <Box sx={{ maxWidth: "1240px", margin: "0 auto" }}>
        {groups && (
          <MTable
            tableConfig={tableConfig}
            setTableConfig={setTableConfig}
            search={false}
            headers={headers}
            records={groups}
            totalLength={groupsLength}
            rowRenderer={(record) => (
              <StyledTableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={record.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                  padding="none"
                  sx={{ textTransform: "capitalize" }}
                >
                  {record.name}
                </StyledTableCell>
                {isDefaultUserGroups(record.name) ? (
                  <>
                    <StyledTableCell align="center"></StyledTableCell>
                  </>
                ) : (
                  <>
                    <StyledTableCell align="center">
                      <MDialogInGroup
                        buttonLabel="Edit"
                        title="Rename record Name"
                        btnHandler={editHandler}
                        data={record}
                      />

                      <ReconfirmBtn
                        btnTitle="Delete"
                        desc="Delete this data?"
                        handleClick={() => deleteHandler(record.id)}
                      />
                    </StyledTableCell>
                  </>
                )}
              </StyledTableRow>
            )}
          />
        )}
      </Box>
    </>
  );
};

export default Groups;
