import { useEffect } from "react";
import {
  withAdminAuthenticated,
  withAuthenticated,
} from "../../lib/context/AuthContext";
import { useEmailHistory } from "../../lib/hooks/useEmailHistory";
import EmailHistoryList from "../../components/email/EmailHistoryList";
import MPage from "../../components/common/MPage";
const EmailHistory = () => {
  const useEmailHistoryHooks = useEmailHistory();
  const { fetchEmailHistory } = useEmailHistoryHooks;
  useEffect(() => {
    fetchEmailHistory();
  }, [fetchEmailHistory]);

  return (
    <>
      <MPage title="Email History">
        <EmailHistoryList useEmailHistoryHooks={useEmailHistoryHooks} />
      </MPage>
    </>
  );
};

export default withAuthenticated(withAdminAuthenticated(EmailHistory));
