import { useEffect } from "react";
import {
  withAdminAuthenticated,
  withAuthenticated,
} from "../../lib/context/AuthContext";
import TemplatesList from "../../components/email/TemplatesList";
import MDialogEmailTemplate from "../../components/email/MDialogEmailTemplate";
import { useEmailTemplates } from "../../lib/hooks/useEmailTemplates";
import MPage from "../../components/common/MPage";

const EmailTemplates = () => {
  const useEmailTemplatesHook = useEmailTemplates();
  const { fetchEmailTemplate, addTemplateHandler } = useEmailTemplatesHook;

  useEffect(() => {
    fetchEmailTemplate();
  }, [fetchEmailTemplate]);

  return (
    <>
      <MPage
        title="Email Templates"
        header={
          <MDialogEmailTemplate
            buttonLabel="Create Template"
            btnHandler={addTemplateHandler}
            data={{ name: "", title: "", content: "" }}
            buttonWidth="150px"
          />
        }
      >
        <TemplatesList useEmailTemplatesHook={useEmailTemplatesHook} />
      </MPage>
    </>
  );
};

export default withAuthenticated(withAdminAuthenticated(EmailTemplates));
