import { Link as LinkIcon } from "@mui/icons-material";
import { format } from "date-fns";
import { Box, Divider, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { checkState, createHeaders } from "../../lib/utils/utils";

import MTable, { StyledTableCell, StyledTableRow } from "../common/MTable";
import { PrimaryIconBtn } from "../common/Buttons";
import PageHeader from "../common/PageHeader";
import { MInput, MInputLabel } from "../common/MInputs";
import { RowLabelRenderer } from "../common/RowLabelRenderer";

const checkStateText = (state) => {
  let stateText = "";
  switch (state) {
    case 0:
      stateText = "Submitted";
      break;
    case 1:
      stateText = "Signed";
      break;
    case 2:
      stateText = "Deleted";
      break;
    case 3:
      stateText = "Declined";
      break;
    default:
      break;
  }
  return stateText;
};
const quotationsHeaders = [
  createHeaders("Company Name", false, "name"),
  createHeaders("Company Email", false, "email"),
  createHeaders("Status", false, "state"),
  createHeaders("Submitted At", false, "submitTime"),
  createHeaders("Quotations", false, null),
];

const RenderSummary = ({ rfq, rfqSummaryHook, showQuotation }) => {
  let navigate = useNavigate();
  const {
    rfqSubmissionList,
    rfqSubmissionLength,
    tableConfig,
    setTableConfig,
    fetchSupplierLatestPdf,
    rfqReturnBids,
  } = rfqSummaryHook;

  const canShowQuotation = () => {
    if (showQuotation) {
      return rfq?.state === 3 || (rfq?.state === 2 && rfqReturnBids >= 3);
    }
    return false;
  };

  return (
    <>
      {rfq && (
        <>
          <Box
            className="rfq-info-wrapper"
            style={{ display: "flex", justifyContent: "space-between" }}
            sx={{ maxWidth: "1240px", margin: "0 auto" }}
          >
            <Grid container rowSpacing={1} sx={{ margin: "auto" }}>
              <RowLabelRenderer label="RFQ Name">
                <MInput
                  name="name"
                  value={rfq.name}
                  handleChange={(e) => console.log(e)}
                  readOnly={true}
                />
              </RowLabelRenderer>
              <RowLabelRenderer label="Reference Number">
                <MInput
                  name="referenceNumber"
                  value={rfq.referenceNumber}
                  handleChange={(e) => console.log(e)}
                  readOnly={true}
                />
              </RowLabelRenderer>
              <RowLabelRenderer label="Start Date">
                <MInput
                  name="startTime"
                  value={format(new Date(rfq.startTime), "dd/MM/yyyy HH:mm")}
                  readOnly={true}
                  type="text"
                />
              </RowLabelRenderer>
              <RowLabelRenderer label="Due Date">
                <MInput
                  name="dueTime"
                  value={format(new Date(rfq.dueTime), "dd/MM/yyyy HH:mm")}
                  readOnly={true}
                  type="text"
                />
              </RowLabelRenderer>
              <RowLabelRenderer label="Site Applied">
                <MInput
                  name="siteApplied"
                  value={rfq.organization.name || "-"}
                  readOnly={true}
                  type="text"
                />
              </RowLabelRenderer>
              <RowLabelRenderer label="T&amp;C Attachment ">
                <PrimaryIconBtn
                  btnLabel="View"
                  handleClick={() => {
                    if (rfq?.fileId) {
                      return navigate(
                        `/rfq/viewAttachment?pageTitle=T%26C%20Attachment&rfqFileId=${rfq?.fileId}`
                      );
                    }
                  }}
                  icon={<LinkIcon />}
                  disabled={!rfq?.fileId}
                />
              </RowLabelRenderer>
              <Grid item xs={4} sx={{ alignSelf: "center" }}>
                <MInputLabel label="RFQ Status" />
              </Grid>
              <Grid item xs={8}>
                <Grid container rowSpacing={1} sx={{ margin: "auto" }}>
                  <Grid item xs={2}>
                    <MInput
                      name="state"
                      value={checkState(rfq)}
                      readOnly={true}
                    />
                  </Grid>
                  {rfq?.state === 3 && (
                    <>
                      <Grid item xs={2} sx={{ alignSelf: "center" }}>
                        <MInputLabel label="Unlocked By" />
                      </Grid>
                      <Grid item xs={2}>
                        <MInput
                          name="state"
                          value={rfq?.unlockedByUser?.name || "-"}
                          readOnly={true}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              <RowLabelRenderer label="RFQ Owner">
                <MInput
                  name="owner"
                  value={rfq?.user?.name || ""}
                  readOnly={true}
                />
              </RowLabelRenderer>
            </Grid>
          </Box>
          <Box
            style={{ display: "flex", justifyContent: "space-between" }}
            sx={{ maxWidth: "1240px", margin: "80px auto 20px" }}
          >
            <PageHeader title="Suppliers" />
          </Box>
          <Divider />
          <Box sx={{ maxWidth: "1240px", margin: "0 auto" }}>
            <MTable
              tableConfig={tableConfig}
              setTableConfig={setTableConfig}
              search={false}
              headers={quotationsHeaders}
              records={rfqSubmissionList}
              totalLength={rfqSubmissionLength}
              setStartIndex={() => 5}
              //rowsPerPage={5}
              //setRowsPerPage={setRfqSuppliersDefaultRowsPerPage}
              //handleRequest={fetchRfqSupplierList}
              rowRenderer={(record) => (
                <StyledTableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={record.rfqSupplierId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row" padding="none">
                    {record.companyName}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {record.emailAddress}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {canShowQuotation()
                      ? !record.state && record.state !== 0
                        ? "Not Submitted"
                        : checkStateText(record.state)
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {canShowQuotation()
                      ? record.createdAt
                        ? new Date(record.createdAt).toLocaleString()
                        : "-"
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {canShowQuotation() && record.state === 1 && (
                      <PrimaryIconBtn
                        btnLabel="View"
                        handleClick={async () => {
                          const pdfResp = await fetchSupplierLatestPdf(
                            record.rfqSupplierId
                          );
                          if (pdfResp.status === 200) {
                            //console.log(URL.createObjectURL(pdfResp.data));
                            const fileName = `${rfq.name}-${record.companyName}-quotation.pdf`;
                            return navigate(
                              `/rfq/viewAttachment?pageTitle=Submitted Quotation&pdfUrl=${URL.createObjectURL(
                                pdfResp.data
                              )}&fileName=${fileName}`
                            );
                          }
                        }}
                        icon={<LinkIcon />}
                      />
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              )}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default RenderSummary;
