import { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import { Box } from "@mui/material";

import MTable, { StyledTableCell, StyledTableRow } from "../../common/MTable";
import MDialogInSupplier from "./MDialogInSupplier";
import MDialogSendEmail from "./MDialogSendEmail";
import { ReconfirmBtn } from "../../common/Buttons";
import { createHeaders } from "../../../lib/utils/utils";
import { useAuth } from "../../../lib/context/AuthContext";

const preparedRfqHeaders = [
  createHeaders("Supplier Name", false, "name"),
  createHeaders("Supplier Email", false, "email"),
  createHeaders("Action", false, null),
];

const lockSupplierHeaders = [
  createHeaders("Supplier Name", false, "name"),
  createHeaders("Supplier Email", false, "email"),
  createHeaders("Qouation", false, "qouatiion"),
];

const findInvitationTemplateId = (templates) => {
  return templates.find((item) => item.title.toUpperCase() === "RFQ INVITATION")
    ?.id;
};
const SuppliersLists = ({ rfqSupplierHooks, rfq, templates }) => {
  const { isFC, isPM } = useAuth();
  const { state } = rfq;
  const {
    rfqSuppliersLength,
    rfqSuppliersList,
    deleteHandler,
    editHandler,
    sendMail,
    setTableConfig,
    tableConfig,
  } = rfqSupplierHooks;

  return (
    <>
      <Box sx={{ maxWidth: "1240px", margin: "0 auto" }}>
        {rfqSuppliersList && (
          <MTable
            tableConfig={tableConfig}
            setTableConfig={setTableConfig}
            search={false}
            headers={
              state === 0 || state === 1
                ? preparedRfqHeaders
                : lockSupplierHeaders
            }
            records={rfqSuppliersList}
            totalLength={rfqSuppliersLength}
            rowRenderer={(record) => (
              <>
                <StyledTableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={record.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell scope="row" padding="none">
                    {record.companyName}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {record.emailAddress}
                  </StyledTableCell>
                  {state === 0 || state === 1 ? (
                    <>
                      {!isFC() ? (
                        <StyledTableCell align="center" colSpan={3}>
                          <MDialogInSupplier
                            buttonLabel="Edit"
                            icon={<EditIcon />}
                            title="Edit Supplier Info"
                            btnHandler={editHandler}
                            data={{
                              id: record.id,
                              rfqId: rfq.id,
                              companyName: record.companyName,
                              emailAddress: record.emailAddress,
                            }}
                            disabled={!(state === 0)}
                          />
                          <MDialogSendEmail
                            buttonLabel="Send"
                            icon={<EditIcon />}
                            title="Send Email To Supplier"
                            btnHandler={sendMail}
                            data={{
                              template:
                                templates &&
                                findInvitationTemplateId(templates),
                              emailAddress: record.emailAddress,
                              id: record.id,
                            }}
                            templates={templates}
                            disabled={state === 0}
                          />

                          <ReconfirmBtn
                            btnTitle="Delete"
                            desc="Delete this data?"
                            handleClick={() => deleteHandler({ id: record.id })}
                            disabled={!(state === 0)}
                          />
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell align="center" colSpan={3}>
                          <MDialogInSupplier
                            buttonLabel="Edit"
                            icon={<EditIcon />}
                            title="Edit Supplier Info"
                            btnHandler={editHandler}
                            data={{
                              id: record.id,
                              rfqId: rfq.id,
                              companyName: record.companyName,
                              emailAddress: record.emailAddress,
                            }}
                            disabled={true}
                          />
                          <MDialogSendEmail
                            buttonLabel="Send"
                            icon={<EditIcon />}
                            title="Send Email To Supplier"
                            btnHandler={sendMail}
                            data={{
                              template:
                                templates &&
                                findInvitationTemplateId(templates),
                              emailAddress: record.emailAddress,
                              id: record.id,
                            }}
                            templates={templates}
                            disabled={true}
                          />

                          <ReconfirmBtn
                            btnTitle="Delete"
                            desc="Delete this data?"
                            handleClick={() => deleteHandler({ id: record.id })}
                            disabled={true}
                          />
                        </StyledTableCell>
                      )}
                    </>
                  ) : (
                    <StyledTableCell align="center">
                      {record.quotation}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              </>
            )}
          />
        )}
      </Box>
    </>
  );
};

export default SuppliersLists;
