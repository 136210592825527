import {
	Box,
	Button,
	Divider,
	FormControlLabel,
	FormGroup,
	Grid,
	Paper,
	Switch,
	TextField,
	Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import {useSmtpConfig} from "../../../lib/context/SmtpConfigContext";
import {withAuthenticated} from "../../../lib/context/AuthContext";
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import MPage from "../../../components/common/MPage";
import {toast} from "react-toastify";

const defaultSmtp = {
	from: "",
	fromName: "",
	host: "",
	password: "",
	port: 587,
	tls: true,
	username: "",
}

const SmtpPage = () => {
	const smtpConfig = useSmtpConfig()
	const [smtp, setSmtp] = useState(defaultSmtp)
	const [recipient, setRecipient] = useState('')

	const fetchSmtp = async () => {
		const smtpStr = await smtpConfig.getSmtpConfig()
		setSmtp(smtpStr.data.data)
	}

	const saveSmtp = async () => {
		const {error} = await smtpConfig.updateSmtpConfig(smtp)

		if (!error) {
			toast.success("Save SMTP Configuration successfully")

			await fetchSmtp()
		}
	}

	const sendTest = async () => {
		const {error} = await smtpConfig.sendTestSmtp(recipient)

		if (!error) {
			toast.success("Sent test email to " + recipient)
		}
	}

	const handleInputChange = (field, value) => {
		const config = {}
		config[field] = value
		setSmtp(Object.assign({}, smtp, config))
	}

	useEffect(() => {
		fetchSmtp()
	}, [])

	return (
		<MPage
			title={'SMTP Configurations'}
		>
			<Grid container spacing={3}>
				<Grid item sm={12} md={6}>
					<Paper
						sx={{p: 3}}
						component="form"
						noValidate
						autoComplete="off"
					>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Typography variant="h5">
									Configuration
								</Typography>
							</Grid>

							<Grid item xs={12} sm={8}>
								<TextField
									margin="dense"
									name="host"
									type="url"
									fullWidth
									variant="standard"
									value={smtp.host}
									onChange={(e) => handleInputChange('host', e.target.value)}
									label="Host"
									// helperText={error.name ? error.name : ""}
									// error={!!error.name}
								/>
							</Grid>

							<Grid item xs={12} sm={4}>
								<TextField
									margin="dense"
									name="port"
									type="number"
									fullWidth
									variant="standard"
									value={smtp.port}
									onChange={(e) => handleInputChange('port', e.target.value)}
									label="Port"
									// helperText={error.name ? error.name : ""}
									// error={!!error.name}
								/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									margin="dense"
									name="username"
									type="text"
									fullWidth
									variant="standard"
									value={smtp.username}
									onChange={(e) => handleInputChange('username', e.target.value)}
									label="Username"
									// helperText={error.name ? error.name : ""}
									// error={!!error.name}
								/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									margin="dense"
									name="password"
									type="password"
									fullWidth
									variant="standard"
									value={smtp.password}
									onChange={(e) => handleInputChange('password', e.target.value)}
									label="Passowrd"
									// helperText={error.name ? error.name : ""}
									// error={!!error.name}
								/>
							</Grid>

							<Grid item xs={12} sm={8}>
								<TextField
									margin="dense"
									name="from"
									type="email"
									fullWidth
									variant="standard"
									value={smtp.from}
									onChange={(e) => handleInputChange('from', e.target.value)}
									label="From email address"
									// helperText={error.name ? error.name : ""}
									// error={!!error.name}
								/>
							</Grid>

							<Grid item xs={12} sm={4}>
								<TextField
									margin="dense"
									name="fromName"
									type="text"
									fullWidth
									variant="standard"
									value={smtp.fromName}
									onChange={(e) => handleInputChange('fromName', e.target.value)}
									label="From name"
									// helperText={error.name ? error.name : ""}
									// error={!!error.name}
								/>
							</Grid>

							<Grid item xs={12}>
								<FormGroup>
									<FormControlLabel control={
										<Switch
											value={smtp.tls}
											// checked={checked}
											onChange={(e) => handleInputChange('tls', e.target.checked)}
											defaultChecked
										/>
									} label="Enable TLS"/>
								</FormGroup>
							</Grid>

							<Grid item xs={12}>
								<Box sx={{float: 'right'}}>
									<Button
										variant="contained"
										startIcon={<SaveIcon/>}
										onClick={() => saveSmtp()}
									>
										Save
									</Button>
								</Box>
							</Grid>
						</Grid>

						<Box py={3}>
							<Divider/>
						</Box>

						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Typography variant="h5">
									Test configurations
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<TextField
									margin="dense"
									name="recipient"
									type="text"
									fullWidth
									variant="standard"
									value={recipient}
									onChange={(e) => setRecipient(e.target.value)}
									label="Recipient"
								/>
							</Grid>

							<Grid item xs={12}>
								<Box sx={{float: 'right'}}>
									<Button
										variant="contained"
										startIcon={<SendIcon/>}
										onClick={() => sendTest()}
									>
										Send
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
		</MPage>
	)
}

export default withAuthenticated(SmtpPage)
