import { useState, useCallback, useEffect } from "react";
import {
  getAllOrg,
  createOrg,
  deleteOrg,
  updateOrg,
  getOrgById,
  getOrgUsers,
} from "../api/organization";
import { useApi } from "../context/ApiContext";
export default function useOrgs() {
  const api = useApi();
  const [tableConfig, setTableConfig] = useState({
    searchKeyword: "",
    startIndex: 0,
    rowsPerPage: 20,
    order: "",
    orderBy: "",
    filters: null,
    page: 0,
  });
  const [orgs, setOrgs] = useState(null);
  const [orgsLength, setOrgsLength] = useState(0);

  const fetchOrgs = useCallback(() => {
    const fetchingOrgs = async () => {
      const response = await api.runApi(() =>
        getAllOrg({
          q: tableConfig.searchKeyword,
          s: tableConfig.startIndex,
          n: tableConfig.rowsPerPage,
          order: tableConfig.orderBy
            ? `${tableConfig.orderBy}:${tableConfig.order}`
            : "createdAt:desc",
        })
      );
      setOrgsLength(response?.data?.data?.totalLength);
      setOrgs(response?.data?.data?.organizationList);
    };
    fetchingOrgs();
  }, [tableConfig]);

  const addBtnHandler = async (newInput) => {
    await api.runApi(() => createOrg(newInput));
    fetchOrgs();
  };

  const deleteHandler = async (id) => {
    if (id) {
      await api.runApi(() => deleteOrg(id));
      fetchOrgs();
    }
  };

  const editHandler = async (newInput, data) => {
    if (data && newInput) {
      await api.runApi(() => updateOrg(data.id, newInput));
      fetchOrgs();
    }
  };

  return {
    tableConfig,
    setTableConfig,
    orgs,
    orgsLength,
    fetchOrgs,
    addBtnHandler,
    deleteHandler,
    editHandler,
  };
}
