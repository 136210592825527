import { useAuth } from "../lib/context/AuthContext";
import { useEffect } from "react";
import { useNavigate } from "react-router";

const Logout = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    auth.logout();
    navigate("/login");
  }, []);

  return <></>;
};

export default Logout;
