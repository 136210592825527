import { axiosInstance } from "./base";

export const getSmtpConfigAPI = () => axiosInstance.get("/api/config/smtp");

export const updateSmtpConfigAPI = ({
  host,
  port,
  username,
  password,
  from,
  fromName,
  tls,
}) =>
  axiosInstance.post("/api/config/smtp", {
    host,
    port,
    username,
    password,
    from,
    fromName,
    tls,
  });

export const sendTestSmtpAPI = (recipient) =>
  axiosInstance.post("/api/config/smtp/test", {
    recipients: [recipient],
  });
