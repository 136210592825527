import Bottleneck from "bottleneck";
import { toast } from "react-toastify";
import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router";

const apiContext = createContext(null);

const runningApis = [];

// export const isCurrentPageDenyQuery = (location) => {
// 	const globalQueryFilterDeny = [
// 		/^\/$/g,
// 		/^\/idp\/instructions/g,
// 		/^\/idp\/link\/.*/g,
// 	]
//
// 	for (let regExp of globalQueryFilterDeny) {
// 		const matcher = regExp.exec(location.pathname)
// 		if(matcher) {
// 			return true;
// 		}
// 	}
//
// 	console.log(location.pathname, 'no deny')
// 	return false;
// }

const apiLimiter = new Bottleneck({
  maxConcurrent: 5,
});

const randomWaitingDuration = () => {
  // return new Promise((resolve) => setTimeout(resolve, Math.random() * 500 + 500));
  return new Promise((resolve) => setTimeout(resolve, Math.random() * 30 + 20));
};

export const ApiProvider = ({ children }) => {
  const navigate = useNavigate();
  const [apiCount, setApiCount] = useState(0);

  const runApi = async (
    calling,
    process = (data) => data,
    options = {
      noWait: false,
      skip401Error: false,
    }
  ) => {
    runningApis.push(0);
    setApiCount(runningApis.length);

    const data = await buildApiProcess(calling, process, options)();
    runningApis.pop();
    setApiCount(runningApis.length);

    if (data.redirectToLogin) {
      navigate("/logout");
    }

    delete data.redirectToLogin;

    return data;
  };

  return (
    <apiContext.Provider
      value={{
        runApi: runApi,
        isLoadingApi: apiCount > 0,
      }}
    >
      {children}
    </apiContext.Provider>
  );
};

export const useApi = () => useContext(apiContext);

const buildApiProcess = (
  calling,
  process = (data) => data,
  options = {
    noWait: false,
    skip401Error: false,
  }
) => {
  const noWait = options.noWait || false;
  const skip401Error = options.skip401Error || false;

  return apiLimiter.wrap(async (params) => {
    let response = null;
    let error = null;
    let redirectToLogin = false;
    let toastId = null;
    const s = Date.now();

    try {
      response = await calling({ pageParam: params?.pageParam ?? null });
    } catch (e) {
      error = e;
    }

    const e = Date.now();

    if (!noWait && e - s < 20) {
      await randomWaitingDuration();
    }

    if (!response?.data?.status) {
      error = new Error(response?.data?.message || "cannot connect to server");
    }

    if (skip401Error) {
      console.log(response);
    }

    if (
      skip401Error &&
      response?.status === 401 &&
      !response?.data?.status &&
      response?.data?.message === "Unauthorized"
    ) {
      error = null;
    }

    if (!skip401Error && response?.status === 401) {
      redirectToLogin = true;
    }

    if (error) {
      toastId = toast.error(error.message);
    }

    console.log("Api complete", response);

    return process({ ...response, error, redirectToLogin, toastId });
  });
};
