import { useState, useCallback, useEffect } from "react"
import { toast } from "react-toastify"

import { useApi } from "../context/ApiContext"
import { supplierGetMe } from "../api/suppliers"
import {
  declineRfqSubmission,
  deleteRfqSubmission,
  getLatestRfqSubmission,
} from "../api/rfqSubmission"
import {
  getAdobeSignWidgetId,
  getAdobeSignComplete,
} from "../api/adobeSignWidget"
import { getRfqSupplierLatestPdf } from "../../lib/api/rfqSubmission"
//import { getRfqById } from "../api/rfq";
export const useSupplier = () => {
  const api = useApi()
  const [isLoading, setIsLoading] = useState(false)
  const [supplierInfo, setSupplierInfo] = useState(null)
  const [adobeSignUrl, setAdobeSignUrl] = useState(null)
  const [latestRfqSubmission, setLatestRfqSumission] = useState(null)
  const fetchSupplierInfo = useCallback(() => {
    const fetchingUserInfo = async () => {
      const response = await api.runApi(() => supplierGetMe())
      setSupplierInfo(response?.data?.data)
      const rfqSumissionResp = await api.runApi(() =>
        getLatestRfqSubmission(response?.data?.data?.id)
      )
      if (rfqSumissionResp?.data?.data) {
        setLatestRfqSumission(rfqSumissionResp?.data?.data)
      }
    }
    fetchingUserInfo()
  }, [])

  const fetchAdobeSignUrl = async () => {
    setIsLoading(true)
    const response = await api.runApi(() =>
      getAdobeSignWidgetId(supplierInfo.id)
    )
    if (!response?.data?.status) {
      return false
    }
    setAdobeSignUrl(response.data.data)
    setIsLoading(false)
    return response.data.data
  }

  const fetchAdobeSignComplete = async () => {
    setIsLoading(true)

    const response = await api.runApi(() =>
      getAdobeSignComplete(supplierInfo.id)
    )
    //if (!response?.data?.status) {
    //  setIsLoading(false)
    //  return false
    //}
    //console.log("fetch complete", response)

    setIsLoading(false)
    return response
  }
  const fetchDeclineRfqSubmission = async () => {
    const response = await api.runApi(() =>
      declineRfqSubmission({ rfqSupplierId: supplierInfo.id })
    )
    if (!response?.data?.status) {
      return false
    }
    return true
  }
  const fetchDeleteRfqSubmission = async () => {
    const response = await api.runApi(() =>
      deleteRfqSubmission({ rfqSupplierId: supplierInfo.id })
    )
    if (!response?.data?.status) {
      return false
    }
    setIsLoading(false)
    return true
  }

  const fetchSupplierLatestPdf = async (supplierId) => {
    const response = await getRfqSupplierLatestPdf(supplierId)
    return response
  }
  return {
    supplierInfo,
    fetchSupplierInfo,
    fetchAdobeSignUrl,
    adobeSignUrl,
    fetchAdobeSignComplete,
    fetchDeclineRfqSubmission,
    fetchDeleteRfqSubmission,
    fetchSupplierLatestPdf,
    latestRfqSubmission,
    isLoading,
  }
}
