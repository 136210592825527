import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
} from "@mui/material";
import { MDropdown, MInput, MInputLabel } from "../../common/MInputs";
import { validateForm, validateEmail } from "../../../lib/utils/utils";
import { PrimaryBtn, PrimaryIconBtn } from "../../common/Buttons";
import { RowLabelRenderer } from "../../common/RowLabelRenderer";

const originError = {
  emailAddress: false,
};

const FormDialog = ({
  buttonLabel,
  title,
  desc = "",
  btnHandler,
  data,
  icon,
  customWidth,
  templates,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(data);
  const [error, setError] = useState(originError);
  const validateInput = (name, value) => {
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        //case "template":
        //  if (!value) {
        //    stateObj[name] = "Please select Email Template";
        //  }
        //  break;
        case "emailAddress":
          if (!value) {
            stateObj[name] = "Please enter Email Address";
          } else if (!validateEmail(value)) {
            stateObj[name] = "Please enter correct Email";
          }
          break;
        default:
          break;
      }
      return stateObj;
    });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
    validateInput(name, value);
  };
  const handleConfirm = () => {
    btnHandler(input.id, input.template, input.emailAddress);
  };
  const handleClose = () => {
    setOpen(false);
    setInput(data);
    setError(originError);
  };

  useEffect(() => {
    setInput(data);
  }, [data]);

  return (
    <>
      <PrimaryIconBtn
        icon={icon}
        btnLabel={buttonLabel}
        handleClick={handleClickOpen}
        customWidth={customWidth}
        disabled={disabled}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ ".MuiPaper-root": { width: "500px" } }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{desc}</DialogContentText>

          <Grid container rowSpacing={2} sx={{ margin: "auto" }}>
            <RowLabelRenderer label="Template">
              <MDropdown
                label="Email Template"
                dropdownLabel="Select"
                name="template"
                handleChange={(e) =>
                  setInput({ ...input, template: e.target.value })
                }
                options={templates || []}
                value={input.template}
                disabled={true}
              />
            </RowLabelRenderer>

            <RowLabelRenderer label="Email">
              <MInput
                label="Email"
                value={input.emailAddress}
                handleChange={handleInputChange}
                name="emailAddress"
                errorText={error.emailAddress ? error.emailAddress : ""}
                error={!!error.emailAddress}
              />
            </RowLabelRenderer>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start" }}>
          <PrimaryBtn
            btnLabel="Confirm"
            handleClick={() => {
              handleConfirm();
              handleClose();
            }}
            disabled={
              !validateForm(
                { template: input.template, emailAddress: input.emailAddress },
                error
              )
            }
          />
          <PrimaryBtn btnLabel="Cancel" handleClick={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FormDialog;
