import { useEffect } from "react";

import MPage from "../../../components/common/MPage";
import { withSupplierAuthenticated } from "../../../lib/context/AuthContext";
import RenderSubmission from "../../../components/Submission";
import { useSupplier } from "../../../lib/hooks/useSupplier";
const SubmissionIndex = () => {
  const useSupplierHook = useSupplier();
  const { supplierInfo, fetchSupplierInfo } = useSupplierHook;
  useEffect(() => {
    fetchSupplierInfo();
  }, [fetchSupplierInfo]);

  return (
    <>
      <MPage title="Quotation Submission">
        {supplierInfo && <RenderSubmission useSupplierHook={useSupplierHook} />}
      </MPage>
    </>
  );
};

export default withSupplierAuthenticated(SubmissionIndex);
