import { Box } from "@mui/material";
import { format } from "date-fns";

import MTable, { StyledTableCell, StyledTableRow } from "../common/MTable";
import { createHeaders } from "../../lib/utils/utils";

/*
createdAt,createdBy,event,id,name
*/
const headers = [
  createHeaders("Event", false, "event"),
  createHeaders("Name", false, "name"),
  createHeaders("Created By", false, "createdBy"),
  createHeaders("Event Time", false, "createdAt"),
];

const AuditLog = ({ useAuditLogHooks }) => {
  const {
    auditLogs,
    auditLogsLength,
    tableConfig,
    setTableConfig,
    initTableConfig,
  } = useAuditLogHooks;

  return (
    <>
      <Box sx={{ maxWidth: "1240px", margin: "0 auto" }}></Box>
      {auditLogs && (
        <MTable
          tableConfig={tableConfig}
          setTableConfig={setTableConfig}
          search={true}
          headers={headers}
          records={auditLogs}
          totalLength={auditLogsLength}
          initTableConfig={initTableConfig}
          rowRenderer={(record, index) => (
            <StyledTableRow
              hover
              key={record.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <StyledTableCell component="td" scope="row" padding="none">
                {record.event}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  width: "45%",
                  wordBreak: "break-word",
                  borderBottom: "1px solid rgba(224,224,224,1)",
                }}
              >
                {record.name ? record.name : "-"}
              </StyledTableCell>
              <StyledTableCell align="center">
                {record.createdBy ? record.createdBy : "-"}
              </StyledTableCell>
              <StyledTableCell align="center">
                {format(new Date(record.createdAt), "dd/MM/yyyy HH:mm:ss")}
              </StyledTableCell>
            </StyledTableRow>
          )}
        />
      )}
    </>
  );
};

export default AuditLog;
