import { useCallback, useState } from "react";

import { getEmailHistory } from "../api/notification";
import { useApi } from "../context/ApiContext";
export const useEmailHistory = () => {
  const initTableConfig = {
    searchKeyword: "",
    startIndex: 0,
    rowsPerPage: 20,
    order: "desc",
    orderBy: "createdAt",
    filters: null,
    page: 0,
  };
  const api = useApi();
  const [tableConfig, setTableConfig] = useState(initTableConfig);
  const [history, setHistory] = useState([]);
  const [historyLength, setHistoryLength] = useState(0);

  const fetchEmailHistory = useCallback(() => {
    const fetchingEmailHistory = async () => {
      const response = await api.runApi(() =>
        getEmailHistory({
          q: tableConfig.searchKeyword,
          s: tableConfig.startIndex,
          n: tableConfig.rowsPerPage,
          order: tableConfig.orderBy
            ? `${tableConfig.orderBy}:${tableConfig.order}`
            : "createdAt:desc",
        })
      );
      setHistoryLength(response?.data?.data?.totalLength);
      setHistory(response?.data?.data?.emailHistoryResponseList);
    };
    fetchingEmailHistory();
  }, [tableConfig]);

  return {
    fetchEmailHistory,
    history,
    historyLength,
    tableConfig,
    setTableConfig,
    initTableConfig,
  };
};
