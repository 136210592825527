import NavBar from "./NavBar";
import { Box, Container } from "@mui/material";
import PageHeader from "./PageHeader";
import TopLoadingBar from "../TopLoadingBar";

const MPage = ({ title = "", header = <></>, children }) => (
  <>
    <TopLoadingBar />

    <NavBar />

    <Container maxWidth="lg" sx={{ my: 8 }}>
      <Box
        style={{ display: "flex", justifyContent: "space-between" }}
        sx={{ maxWidth: "1240px", margin: "0 auto" }}
      >
        <Box>
          <PageHeader title={title} />
        </Box>
        <Box>{header ?? <></>}</Box>
      </Box>

      <Box
        sx={{
          py: 3,
          maxWidth: "1240px",
          margin: "0 auto",
        }}
      >
        {children}
      </Box>
    </Container>
  </>
);

export default MPage;
