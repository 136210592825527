import { createContext, useContext, useEffect, useState } from "react";
import { getAccessToken, setAccessToken } from "../utils/storeToken";
import { updateAxiosAccessToken } from "../api/base";
import { loginApi, loginSupplierAPI, verifyLoginAPI } from "../api/auth";
import { useApi } from "./ApiContext";
import { useNavigate } from "react-router";
import LoginPage from "../../pages/login";

const authContext = createContext(null);

const getAccessTokenFromStorage = () => getAccessToken();

export const saveAccessTokenToStorage = (token) => setAccessToken(token);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const api = useApi();
  const [init, setInit] = useState(true);
  const [user, setUser] = useState(null);
  const isAuthenticated = !!user;

  const checkLogin = async () => {
    const response = await api.runApi(
      () => verifyLoginAPI(),
      (d) => d,
      {
        noWait: true,
        skip401Error: true,
      }
    );
    console.log("checkLogin", response?.data?.data);
    setUser(response?.data?.data);
    setInit(false);

    if (!response?.data?.data) {
      updateAxiosAccessToken(null);
      saveAccessTokenToStorage(null);
    }
  };

  const postLogin = async (response) => {
    const accessToken = response?.data?.data?.accessToken;
    console.log("login", response);
    if (accessToken) {
      updateAxiosAccessToken(accessToken);
      saveAccessTokenToStorage(accessToken);
      await checkLogin();
    }

    return !!accessToken;
  };

  const login = async (email, password) => {
    const response = await api.runApi(() => loginApi(email, password));
    return postLogin(response);
  };

  const loginSupplier = async (token) => {
    const response = await api.runApi(() => loginSupplierAPI(token));
    return postLogin(response);
  };

  const logout = () => {
    updateAxiosAccessToken(null);
    saveAccessTokenToStorage(null);
    setUser(null);
    if (isAuthenticated && isSupplier()) {
      return window.close();
    }
    navigate("/");
  };

  //const isAuthenticated = !!user;

  const checkAdmin = () => {
    if (!user || !isAdmin()) {
      navigate("/");
      return false;
    }
    return true;
  };

  const isFC = () => {
    return user?.role && (user?.role).toLowerCase() === "financial controller";
  };
  const isPM = () => {
    return user?.role && (user?.role).toLowerCase() === "purchasing manager";
  };
  const isSupplier = () => {
    return user?.role && (user?.role).toLowerCase() === "supplier";
  };
  const isAdminRole = () => {
    return user?.role && (user?.role).toLowerCase() === "admin";
  };
  const isAdmin = () => {
    return (
      (user?.role && (user?.role).toLowerCase() === "admin") ||
      (user?.role && (user?.role).toLowerCase() === "purchasing manager") ||
      (user?.role && (user?.role).toLowerCase() === "financial controller")
    );
  };

  const checkPageLogin = (supplier = false, admin = false) => {
    console.log(init, isAuthenticated);
    if (init) {
      return;
    }

    if (!init && !isAuthenticated) {
      navigate("/login");
      return;
    }

    if (supplier && !isSupplier()) {
      navigate("/supplier/401");
    } else if (admin && !isAdmin()) {
      navigate("/login");
    }
  };

  useEffect(() => {
    const accessToken = getAccessTokenFromStorage();
    updateAxiosAccessToken(accessToken);
    if (accessToken) {
      checkLogin();
    }
    //checkLogin().then(() => {
    //  setInit(false);
    //});
  }, []);

  return (
    <authContext.Provider
      value={{
        login,
        loginSupplier,
        logout,
        checkPageLogin,
        init,
        isAuthenticated,
        user,
        checkAdmin,
        isSupplier,
        isAdmin,
        isFC,
        isPM,
        isAdminRole,
      }}
    >
      {children}
    </authContext.Provider>
  );
};

export const useAuth = () => {
  //console.log("useAuth", useContext(authContext));
  return useContext(authContext);
};

export const withAuthenticated = (Component) => (props) => {
  const auth = useAuth();
  useEffect(() => {
    auth.checkPageLogin(false, true);
  }, [auth]);
  return auth.isAuthenticated ? <Component {...props} /> : <LoginPage />;

  // return <Component {...props} />
};

export const withSupplierAuthenticated = (Component) => (props) => {
  const auth = useAuth();

  const supplier = auth.isSupplier();

  useEffect(() => {
    auth.checkPageLogin(true);
  }, [auth]);

  return supplier ? <Component {...props} /> : <></>;
};

export const withAdminAuthenticated = (Component) => (props) => {
  const auth = useAuth();
  useEffect(() => {
    auth.checkAdmin();
  }, [auth]);
  return auth.checkAdmin() ? <Component {...props} /> : <></>;

  // return <Component {...props} />
};
