import { createContext, useCallback, useContext, useState } from "react";
import { toast } from "react-toastify";

import { useApi } from "./ApiContext";
import { isDefaultUserGroups } from "../utils/utils";

import {
  createUserGroups,
  deleteUserGroups,
  getAllUserGroups,
  updateUserGroup,
} from "../api/userGroups";

const groupsContext = createContext(null);

export const GroupsProvider = ({ children }) => {
  const api = useApi();
  const [tableConfig, setTableConfig] = useState({
    searchKeyword: "",
    startIndex: 0,
    rowsPerPage: 20,
    order: "",
    orderBy: "",
    filters: null,
    page: 0,
  });
  const [groups, setGroups] = useState(null);
  const [groupsLength, setGroupsLength] = useState(0);

  const fetchGroups = useCallback(() => {
    const fetchingGroups = async () => {
      const response = await api.runApi(() =>
        getAllUserGroups({
          q: tableConfig.searchKeyword,
          s: tableConfig.startIndex,
          n: tableConfig.rowsPerPage,
          order: tableConfig.orderBy
            ? `${tableConfig.orderBy}:${tableConfig.order}`
            : "createdAt:desc",
        })
      );
      setGroupsLength(response?.data?.data?.totalLength);
      setGroups(response?.data?.data?.userGroupList);
    };
    fetchingGroups();
  }, [tableConfig]);

  const addBtnHandler = async (newGroupInput) => {
    if (isDefaultUserGroups(newGroupInput)) {
      return toast.error("User group is already exists.");
    }
    await api.runApi(() => createUserGroups(newGroupInput));
    fetchGroups();
  };

  const deleteHandler = async (id) => {
    if (id) {
      await api.runApi(() => deleteUserGroups(id));
      fetchGroups();
    }
  };

  const editHandler = async (newGroupName, data) => {
    if (isDefaultUserGroups(newGroupName)) {
      return toast.error("User group is already exists.");
    }

    if (data && newGroupName) {
      await api.runApi(() => updateUserGroup(data.id, newGroupName));

      fetchGroups();
    }
  };

  return (
    <groupsContext.Provider
      value={{
        groups,
        addBtnHandler,
        deleteHandler,
        editHandler,
        fetchGroups,
        groupsLength,
        tableConfig,
        setTableConfig,
      }}
    >
      {children}
    </groupsContext.Provider>
  );
};

export const useGroups = () => {
  return useContext(groupsContext);
};
