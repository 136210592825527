import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";

const btnStyle = {
  width: "110px",
  padding: "6px 14px",
  borderRadius: "18px",
  marginLeft: "4px",
  //maxWidth: "120px",
  textTransform: "inherit",
  //minWidth: "80px",
};
export const PrimaryLoadingButton = ({
  btnLabel,
  handleClick,
  icon = null,
  loading = false,
  disabled = false,
  customWidth,
}) => {
  return (
    <LoadingButton
      color="primary"
      onClick={handleClick}
      loading={loading}
      //loadingPosition="start"
      //startIcon={icon}
      variant="contained"
      sx={btnStyle}
      disabled={disabled}
    >
      {btnLabel}
    </LoadingButton>
  );
};
export const OutlinedBtn = ({ btnLabel, handleClick }) => (
  <Button
    variant="outlined"
    color="secondary"
    onClick={handleClick}
    sx={btnStyle}
  >
    {btnLabel}
  </Button>
);
export const PrimaryIconBtn = ({
  btnLabel,
  handleClick,
  type,
  icon,
  disabled = false,
  customWidth,
}) => (
  <Button
    variant="contained"
    startIcon={icon}
    color="primary"
    type={type || "button"}
    onClick={handleClick}
    sx={customWidth ? { ...btnStyle, width: customWidth } : btnStyle}
    disabled={disabled}
  >
    {btnLabel}
  </Button>
);
export const PrimaryBtn = ({
  btnLabel,
  handleClick,
  type,
  disabled = false,
}) => (
  <Button
    variant="contained"
    color="primary"
    type={type || "button"}
    onClick={handleClick}
    sx={btnStyle}
    disabled={disabled}
  >
    {btnLabel}
  </Button>
);

export const ReconfirmBtn = ({
  btnTitle,
  title = "",
  desc = "",
  handleClick,
  icon = <DeleteIcon />,
  color = "secondary",
  disabled = false,
  customWidth,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleConfrim = () => {
    handleClick();
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color={color}
        startIcon={icon}
        //style={btnStyle}
        sx={customWidth ? { ...btnStyle, width: customWidth } : btnStyle}
        onClick={handleClickOpen}
        disabled={disabled}
      >
        {btnTitle}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {desc}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryBtn btnLabel="Confirm" handleClick={handleConfrim} />
          <PrimaryBtn btnLabel="Cancel" handleClick={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  );
};
