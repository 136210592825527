import { createContext, useContext } from "react";
import { useApi } from "./ApiContext";
import {
  getAdobeSignAuthorizeUrlAPI,
  getAdobeSignAuthValidAPI,
  getAdobeSignConfigAPI,
  submitAdobeSignAuthCodeAPI,
  updateAdobeSignConfigAPI,
} from "../api/adobeSign";

const adobeSignContext = createContext(null);

export const AdobeSignProvider = ({ children }) => {
  const api = useApi();

  const getAdobeSignConfig = () => api.runApi(() => getAdobeSignConfigAPI());

  const updateAdobeSignConfig = (adobeSignConfig) =>
    api.runApi(() => updateAdobeSignConfigAPI(adobeSignConfig));

  const getIsAdobeSignAuthTokenValid = () =>
    api.runApi(() => getAdobeSignAuthValidAPI());

  const getAdobeSignAuthUrl = () =>
    api.runApi(() => getAdobeSignAuthorizeUrlAPI());

  const submitAdobeSignAuthCode = (codeAndState) =>
    api.runApi(() => submitAdobeSignAuthCodeAPI(codeAndState));

  return (
    <adobeSignContext.Provider
      value={{
        getAdobeSignConfig,
        updateAdobeSignConfig,
        getIsAdobeSignAuthTokenValid,
        getAdobeSignAuthUrl,
        submitAdobeSignAuthCode,
      }}
    >
      {children}
    </adobeSignContext.Provider>
  );
};

export const useAdobeSign = () => useContext(adobeSignContext);
