import { Box } from "@mui/material";
import { format } from "date-fns";

import MTable, { StyledTableCell, StyledTableRow } from "../common/MTable";
import { createHeaders } from "../../lib/utils/utils";

const headers = [
  createHeaders("Recipient", false, "recipient"),
  createHeaders("RFQ Name", false, "rfqName"),
  createHeaders("Company Name", false, "rfqSupplierCompanyName"),
  createHeaders("Email Template", false, "emailTemplateName"),
  createHeaders("Sender", false, "userName"),
  createHeaders("Sent Time", false, "createdAt"),
];
const EmailHistoryList = ({ useEmailHistoryHooks }) => {
  const {
    history,
    historyLength,
    tableConfig,
    setTableConfig,
    initTableConfig,
  } = useEmailHistoryHooks;

  return (
    <>
      <Box sx={{ maxWidth: "1240px", margin: "0 auto" }}></Box>
      {history && (
        <MTable
          tableConfig={tableConfig}
          setTableConfig={setTableConfig}
          search={true}
          headers={headers}
          records={history}
          totalLength={historyLength}
          initTableConfig={initTableConfig}
          rowRenderer={(record, index) => (
            <StyledTableRow
              hover
              key={record.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <StyledTableCell align="center">
                {record.recipient}
              </StyledTableCell>
              <StyledTableCell align="center">
                {record.rfqName ? record.rfqName : "-"}
              </StyledTableCell>
              <StyledTableCell align="center">
                {record.rfqSupplierCompanyName
                  ? record.rfqSupplierCompanyName
                  : "-"}
              </StyledTableCell>
              <StyledTableCell align="center">
                {record.emailTemplateName}
              </StyledTableCell>
              <StyledTableCell align="center">
                {record.createdByName}
              </StyledTableCell>
              <StyledTableCell align="center">
                {format(new Date(record.createdAt), "dd/MM/yyyy HH:mm")}
              </StyledTableCell>
            </StyledTableRow>
          )}
        />
      )}
    </>
  );
};

export default EmailHistoryList;
