import Axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_URL ?? "http://localhost:8080";

export const axiosInstance = Axios.create({
  baseURL,
  validateStatus: (_) => true,
  headers: {
    "Content-Type": "application/json",
  },
});

export const updateAxiosAccessToken = (accessToken) => {
  if (accessToken) {
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${accessToken}`;
  } else {
    if ("Authorization" in axiosInstance.defaults.headers.common) {
      delete axiosInstance.defaults.headers.common["Authorization"];
    }
  }
};
