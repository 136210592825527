import { useCallback, useState, useEffect } from "react"
import { toast } from "react-toastify"

import { getAllUsersAPI } from "../api/user"
import { getFile, getRfqById, uploadFile } from "../api/rfq"
import { useAuth } from "../context/AuthContext"
import { useApi } from "../context/ApiContext"
import { getAllOrg } from "../api/organization"

const originError = {
  name: false,
  referenceNumber: false,
  startTime: false,
  dueTime: false,
  siteApplied: false,
}

export default function useRfqInfo(isReadMode) {
  const api = useApi()
  const { user, isAdminRole, isSupplier } = useAuth()
  const [attachment, setAttachment] = useState("")
  const [input, setInput] = useState()
  const [error, setError] = useState(originError)
  const [rfqOwner, setRfqOwner] = useState()
  const [orgs, setOrgs] = useState([])
  const [rfq, setRfq] = useState()
  const [users, setUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchUsers = useCallback(() => {
    const fetchingUsers = async () => {
      const response = await api.runApi(() =>
        getAllUsersAPI({ q: "", s: "", n: "", order: "" })
      )
      setUsers(response?.data?.data?.userList || [])
    }
    fetchingUsers()
  }, [])

  const fetchOrgs = useCallback(() => {
    const fetchingOrgs = async () => {
      const response = await api.runApi(() =>
        getAllOrg({
          q: "",
          s: "",
          n: "",
          order: "",
        })
      )
      setOrgs(response?.data?.data?.organizationList)
    }
    fetchingOrgs()
  }, [])

  const fetchGetFile = async (id) => {
    const response = await api.runApi(() => getFile(id))
    console.log("get file response", response)
    setAttachment(response?.data?.data)
  }

  const fetchRfq = useCallback(
    (id) => {
      const fetchingRfq = async () => {
        const response = await api.runApi(() => getRfqById(id))
        if (response?.data?.data?.fileId) {
          //await fetchGetFile(response?.data?.data.fileId);
          const fileResponse = await api.runApi(() =>
            getFile(response?.data?.data.fileId)
          )
          setAttachment(fileResponse?.data?.data)
        }
        setRfqOwner({ id: response?.data?.data?.owner })
        if (isAdminRole() && !isReadMode) {
          console.log("fetch users")
          fetchUsers()
        }
        if (!isSupplier()) {
          fetchOrgs()
        }
        setRfq(response?.data?.data)
      }
      fetchingRfq()
    },
    [isReadMode, fetchUsers, fetchOrgs]
  )

  const handleFileInputChange = (e) => {
    setAttachment((prev) => e?.target?.files[0] || "")
    validateInput("file", e?.target?.files[0])
    console.log(e?.target?.files[0])
  }
  const handleFileDelete = () => {
    handleFileInputChange()
    setAttachment("")
  }

  const validateInput = (name, value) => {
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" }

      switch (name) {
        case "name":
          if (!value) {
            stateObj[name] = "Please enter RFQ name"
          }
          if (
            value &&
            (/\\/gm.test(value) || /[\b\f\n\r\t\v\0%?:|<>*]/gm.test(value))
          ) {
            stateObj[name] = "RFQ name cannot include \\"
          }
          break
        case "referenceNumber":
          if (!value) {
            stateObj[name] = "Please enter Reference Number."
          }
          if (
            value &&
            (/\\/gm.test(value) || /[\b\f\n\r\t\v\0]/gm.test(value))
          ) {
            stateObj[name] = "Reference Number cannot include \\"
          }
          break

        case "startTime":
          if (!value) {
            stateObj[name] = "Please enter Start Date."
          } else if (input.dueTime && input.dueTime <= value) {
            stateObj["dueTime"] = "Please check Date."
          } else {
            stateObj[name] = false
          }
          break
        case "dueTime":
          if (!value) {
            stateObj[name] = "Please enter Submission Due Date."
          } else if (value <= input.startTime) {
            stateObj[name] = "Please check Date."
          } else if (input.startTime && input.startTime >= value) {
            stateObj["startTime"] = "Please check Date."
          } else {
            stateObj[name] = false
          }
          break
        case "siteApplied":
          if (!value) {
            stateObj[name] = "Site Applied could not be empty."
          }
          break

        case "file":
          if (value && value.size > 10 * 1024 * 1024) {
            stateObj[name] = "Size Over Limit."
          }
          if (value && value.type !== "application/pdf") {
            stateObj[name] = "Only Accept PDF File Type."
            toast.warning("File type should be PDF.")
          }
          break
        default:
          break
      }
      return stateObj
    })
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setInput({
      ...input,
      [name]: value,
    })
    validateInput(name, value)
  }
  const handleOptions = (e) => {
    setInput({
      ...input,
      siteApplied: e.target.value,
    })
    validateInput("siteApplied", e.target.value)
  }

  const handleFileUpload = async () => {
    const formData = new FormData()
    formData.append("file", attachment, attachment?.name)
    const response = await api.runApi(() => uploadFile(formData))
    if (response.status) {
      const newFileId = response?.data?.data?.id
      return newFileId
    }
  }

  const handleConfirm = async (submitHandler) => {
    setIsLoading(true)
    let submitResult = true
    if (!input?.fileId) {
      if (attachment) {
        const fileId = await handleFileUpload()
        if (!fileId) {
          return (submitResult = false)
        }
        submitResult = await submitHandler({ ...input, fileId })
      } else {
        submitResult = await submitHandler({
          ...input,
        })
      }
    } else {
      if (attachment && input.fileId === attachment.id) {
        submitResult = await submitHandler({ ...input })
      } else if (attachment && input.fileId !== attachment.id) {
        const fileId = await handleFileUpload()
        if (!fileId) {
          return (submitResult = false)
        }
        submitResult = await submitHandler({ ...input, fileId })
      } else if (!attachment) {
        submitResult = await submitHandler({
          ...input,
          fileId: "00000000-0000-0000-0000-000000000000",
        })
      }
    }
    setIsLoading(false)
    return submitResult
  }

  const handleCancel = () => {
    setAttachment("")
    setError(originError)
  }

  return {
    attachment,
    setAttachment,
    input,
    setInput,
    error,
    setError,
    handleFileInputChange,
    handleFileDelete,
    validateInput,
    handleInputChange,
    handleOptions,
    handleConfirm,
    handleCancel,
    handleFileUpload,
    //fetchGetUser,
    rfqOwner,
    setRfqOwner,
    fetchGetFile,
    orgs,
    fetchOrgs,
    rfq,
    fetchRfq,
    fetchUsers,
    users,
    isLoading,
  }
}
