import { useEffect, useState } from "react";
import {
  withAdminAuthenticated,
  withAuthenticated,
} from "../../../lib/context/AuthContext";
import { useAdobeSign } from "../../../lib/context/AdobeSignContext";
import { toast } from "react-toastify";
import MPage from "../../../components/common/MPage";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

const defaultAdobeSign = {
  client_id: "",
  client_secret: "",
  redirect_uri: "",
  scope:
    "user_login:self+agreement_read:self+agreement_send:self+agreement_write:self+library_read:self+library_write:self+widget_read:self+widget_write:self",
};

const AdobeSignPage = () => {
  const adobeSign = useAdobeSign();
  const [config, setConfig] = useState(defaultAdobeSign);
  const [isTokenValid, setTokenValid] = useState(false);

  const fetchConfig = async () => {
    const configResp = await adobeSign.getAdobeSignConfig();
    setConfig(configResp.data.data);
  };

  const saveConfig = async () => {
    console.log(config);
    const { error } = await adobeSign.updateAdobeSignConfig(config);

    if (!error) {
      toast.success(
        "Save Adobe Sign Authentication Configuration successfully"
      );

      await fetchConfig();
    }
  };

  const getIfTokenValid = async () => {
    const response = await adobeSign.getIsAdobeSignAuthTokenValid();
    console.log(response.data.data);
    if (response.data.status) {
      setTokenValid(true);
    }
  };

  const handleInputChange = (field, value) => {
    const newConfig = {};
    newConfig[field] = value;
    setConfig(Object.assign({}, config, newConfig));
    setTokenValid(false);
  };

  const startAuth = async () => {
    const response = await adobeSign.getAdobeSignAuthUrl();
    window.location.assign(response.data.data);
    console.log(response.data.data);
  };

  useEffect(() => {
    fetchConfig().then((_) => {});

    getIfTokenValid().then((_) => {});
  }, []);

  return (
    <MPage title={"Adobe Sign Configuration"}>
      <Grid container spacing={3}>
        <Grid item sm={12} md={6}>
          <Paper sx={{ p: 3 }} component="form" noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">Configuration</Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  name="client_id"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={config.client_id}
                  onChange={(e) =>
                    handleInputChange("client_id", e.target.value)
                  }
                  label="Client ID"
                  // helperText={error.name ? error.name : ""}
                  // error={!!error.name}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  name="client_secret"
                  type="password"
                  fullWidth
                  variant="standard"
                  value={config.client_secret}
                  onChange={(e) =>
                    handleInputChange("client_secret", e.target.value)
                  }
                  label="Client Secret"
                  // helperText={error.name ? error.name : ""}
                  // error={!!error.name}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  margin="dense"
                  name="redirect_uri"
                  type="url"
                  fullWidth
                  variant="standard"
                  value={config.redirect_uri}
                  onChange={(e) =>
                    handleInputChange("redirect_uri", e.target.value)
                  }
                  label="Redirect Uri"
                  helperText={"https://<domain>/adobesign/auth/code"}
                  // error={!!error.name}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  name="scope"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={config.scope}
                  onChange={(e) => handleInputChange("scope", e.target.value)}
                  label="Scope"
                  // helperText={error.name ? error.name : ""}
                  // error={!!error.name}
                />
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ float: "right" }}>
                  <Button
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() => saveConfig()}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item sm={12} md={6}>
          <Paper sx={{ p: 3 }} component="form" noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">Authentication</Typography>
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={isTokenValid} disabled />}
                    label={`${
                      isTokenValid
                        ? "Access Token valid"
                        : "Access Token unvalid"
                    }`}
                    // labelPlacement="start"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={() => startAuth()}
                >
                  Authenticate / Re-authenticate
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </MPage>
  );
};

export default withAuthenticated(withAdminAuthenticated(AdobeSignPage));
