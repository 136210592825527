import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import { PrimaryBtn, PrimaryIconBtn } from "../common/Buttons";

const originError = {
  password: false,
};

const FormDialog = ({
  buttonLabel,
  title,
  desc,
  btnHandler,
  data,
  icon = "",
  name,
  inputLabel,
  isDisabled,
}) => {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");
  const [error, setError] = useState(originError);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleInputChange = (e) => {
    setError(originError);

    setInput(e.target.value);
    //if (!e.target.value.trim()) {
    //  setError({ [e.target.name]: "Please Enter Group Name" });
    //}
  };
  const handleConfirm = () => {
    if (input.trim()) {
      btnHandler(input);
      setOpen(false);
      setInput("");
    }
  };
  const handleClose = () => {
    setOpen(false);
    setInput("");
    setError(originError);
  };

  return (
    <>
      <PrimaryIconBtn
        btnLabel={buttonLabel}
        handleClick={handleClickOpen}
        icon={icon}
        disabled={isDisabled}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{desc}</DialogContentText>
          <TextField
            margin="dense"
            id="name"
            name={name}
            type="password"
            fullWidth
            variant="standard"
            value={input}
            onChange={(e) => handleInputChange(e)}
            label={inputLabel}
            //helperText={error.userGroups ? error.userGroups : ""}
            //error={!!error.userGroups}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "start" }}>
          <PrimaryBtn btnLabel="Confirm" handleClick={handleConfirm} />
          <PrimaryBtn btnLabel="Cancel" handleClick={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FormDialog;
