import { useRef } from "react";
import {
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export const MInputLabel = ({ label }) => {
  return (
    <InputLabel
      sx={{
        width: "100%",
        display: "inline",
        whiteSpace: "pre-wrap",
        color: "#4E4D4D",
        fontWeight: "bold",
        fontSize: "14px",
      }}
      variant="outlined"
    >
      {label}
    </InputLabel>
  );
};

export const MInput = ({
  type = "text",
  value,
  handleChange = () => console.log("handleChange"),
  readOnly = false,
  name = "",
  error = false,
  errorText = "",
}) => {
  return (
    <>
      <InputBase
        className={readOnly ? "input-readonly" : ""}
        sx={{
          width: "100%",
          background: "#F6FBFF",
          padding: "6px 10px",
          borderRadius: "10px",
          color: "#7D7C7C",
          "&.input-readonly": {
            //background: "#ebebeb",
            background: "#F4F8FB",
            color: "#359DB4",
          },
          fontSize: "14px",
        }}
        name={name}
        type={type}
        value={value}
        onChange={handleChange}
        required
        error={error}
        readOnly={readOnly}
      />
    </>
  );
};

export const TextareaAutosizeSideLable = ({
  value,
  handleChange = () => console.log("handleChange"),
  readOnly = false,
  error = false,
  errorText = "",
  name = "",
  placeholder = "",
  maxRows,
}) => {
  return (
    <>
      <TextareaAutosize
        className={readOnly ? "rfq-textarea input-readonly" : "rfq-textarea"}
        minRows={6}
        maxRows={maxRows ? maxRows : 8}
        placeholder={placeholder}
        name={name}
        value={value}
        readOnly={readOnly}
        onChange={handleChange}
      />
    </>
  );
};

export const MDropdown = ({
  dropdownLabel = "Select ...",
  name,
  handleChange = () => console.log("handleChange"),
  options,
  value,
  error = false,
  errorText = "",
  disabled = false,
}) => {
  return (
    <>
      <FormControl
        className="dropdown"
        sx={{
          width: "100%",
          background: "#F6FBFF",
          borderRadius: "10px",
          color: "#7D7C7C",
        }}
        error={error}
        disabled={disabled}
      >
        <Select
          defaultValue={value}
          value={value}
          onChange={handleChange}
          //onOpen={handleChange}
          displayEmpty
          name={name}
        >
          <MenuItem
            value=""
            className="dropdown-li"
            sx={{ textTransform: "capitalize" }}
          >
            {dropdownLabel}
          </MenuItem>
          {options &&
            options.map((item) => {
              return (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  id={item.id}
                  sx={{ textTransform: "capitalize" }}
                >
                  {item.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </>
  );
};

export const MFileInput = ({
  name,
  value,
  btnLabel,
  handleFileInputChange,
  handleFileDelete,
  //handleUpload,
  acceptType = "application/pdf",
  error,
}) => {
  const fileInput = useRef(null);

  const handleFileBtn = () => {
    fileInput.current.click();
  };

  return (
    <>
      <TextField
        className="inputfile"
        margin="dense"
        name={name}
        type="text"
        variant="standard"
        fullWidth
        readOnly
        error={error}
        value={value}
        sx={{
          "& input": {
            color: "#7d7c7c",
            fontSize: "14px",
            padding: "6px 10px",
          },
        }}
        InputProps={{
          startAdornment: (
            <>
              <button className="inputfile-button" onClick={handleFileBtn}>
                {btnLabel}
              </button>
              <input
                ref={fileInput}
                type="file"
                style={{ display: "none" }}
                onChange={(e) => {
                  handleFileInputChange(e);
                }}
                onClick={(e) => (e.target.value = null)}
                accept={acceptType}
              />
            </>
          ),
          endAdornment: (
            <>
              {/*<IconButton
                onClick={(e) => handleUpload(e)}
                sx={{
                  background: "#349DB4",
                  borderRadius: "10px",
                  padding: "4px",
                  marginRight: "4px",
                }}
              >
                <CloudUploadIcon sx={{ color: "#ffffff" }} />
              </IconButton>*/}
              <IconButton
                onClick={(e) => handleFileDelete(e)}
                sx={{
                  background: "#f1776c",
                  borderRadius: "10px",
                  padding: "4px",
                }}
              >
                <DeleteIcon sx={{ color: "#ffffff" }} />
              </IconButton>
            </>
          ),
        }}
      />
    </>
  );
};
