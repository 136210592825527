import { useEffect, useState } from "react";
import { isValid, addWeeks, addMonths } from "date-fns";
import { toast } from "react-toastify";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";

import { useRfqs } from "../../lib/context/RfqsContext";
import { PrimaryBtn, PrimaryLoadingButton } from "../common/Buttons";
import {
  MDropdown,
  MFileInput,
  MInput,
  TextareaAutosizeSideLable,
} from "../common/MInputs";
import MDatePicker from "../common/MDatePicker";
import useRfqInfo from "../../lib/hooks/useRfqInfo";
import { RowLabelRenderer } from "../common/RowLabelRenderer";
//import { useAuth } from "../../lib/context/AuthContext";
import { validateForm } from "../../lib/utils/utils";
import { formatRfqDate } from "../../lib/utils/utils";

export default function FormDialog({
  rfqInfoHook,
  buttonLabel,
  title,
  desc = "",
}) {
  const {
    attachment,
    //setAttachment,
    input,
    setInput,
    error,
    handleFileInputChange,
    handleFileDelete,
    validateInput,
    handleInputChange,
    handleOptions,
    handleConfirm,
    handleCancel,
    //handleFileUpload,
    orgs,
    fetchOrgs,
    isLoading,
  } = rfqInfoHook;
  const { addBtnHandler } = useRfqs();
  const [open, setOpen] = useState(false);
  const now = new Date();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    handleCancel();
    setOpen(false);
  };

  useEffect(() => {
    setInput({
      name: "",
      referenceNumber: "",
      startTime: formatRfqDate(addMonths(now, 1)).toISOString(),
      dueTime: formatRfqDate(addMonths(addWeeks(now, 1), 1)).toISOString(),
      description: "",
      siteApplied: "",
      fileId: "",
      state: 0,
    });
    if (open) {
      fetchOrgs();
    }
  }, [open, fetchOrgs]);

  //useEffect(() => {
  //  console.log(error);
  //  console.log(input);
  //}, [error, input]);

  return (
    <>
      <PrimaryBtn btnLabel={buttonLabel} handleClick={handleClickOpen} />
      {input && (
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="600px"
          width="600px"
        >
          <DialogTitle>{title}</DialogTitle>
          <Divider />

          <DialogContent>
            <DialogContentText>{desc}</DialogContentText>
            <Grid container rowSpacing={1} sx={{ margin: "auto" }}>
              <RowLabelRenderer label="RFQ Name">
                <MInput
                  name="name"
                  value={input.name}
                  handleChange={(e) => handleInputChange(e)}
                  errorText={error.name ? error.name : ""}
                  error={!!error.name}
                />
              </RowLabelRenderer>
              <RowLabelRenderer label="Reference Number">
                <MInput
                  name="referenceNumber"
                  value={input.referenceNumber}
                  handleChange={(e) => handleInputChange(e)}
                  errorText={error.referenceNumber ? error.referenceNumber : ""}
                  error={!!error.referenceNumber}
                />
              </RowLabelRenderer>
              <RowLabelRenderer label="Start Date">
                <MDatePicker
                  currentTime={now}
                  error={!!error.startTime}
                  value={input.startTime}
                  handleChange={(newValue) => {
                    if (newValue && isValid(newValue)) {
                      const newDate = new Date(newValue).toISOString();
                      setInput({ ...input, startTime: newDate });
                      if (input?.dueTime) {
                        validateInput("dueTime", input.dueTime);
                      }
                      return validateInput("startTime", newDate);
                    }
                  }}
                />
              </RowLabelRenderer>
              <RowLabelRenderer label="Due Date">
                <MDatePicker
                  currentTime={now}
                  error={!!error.dueTime}
                  value={input.dueTime}
                  handleChange={(newValue) => {
                    console.log(newValue);
                    if (newValue && isValid(newValue)) {
                      const newDate = new Date(newValue).toISOString();
                      setInput({ ...input, dueTime: newDate });
                      if (input?.startTime) {
                        validateInput("startTime", input.startTime);
                      }
                      return validateInput("dueTime", newDate);
                    }
                  }}
                />
              </RowLabelRenderer>
              <RowLabelRenderer label="Site Applied">
                <MDropdown
                  dropdownLabel="Please Select"
                  name="siteApplied"
                  handleChange={(e) => handleOptions(e)}
                  options={orgs}
                  value={input?.siteApplied || ""}
                  error={!!error.siteApplied}
                  errorText={error.siteApplied}
                />
              </RowLabelRenderer>
              <RowLabelRenderer label="Edit Description">
                <TextareaAutosizeSideLable
                  name="description"
                  value={input.description}
                  handleChange={handleInputChange}
                />
              </RowLabelRenderer>

              <RowLabelRenderer label="T&amp;C Attachment (<10MB)">
                <MFileInput
                  name="attachment"
                  value={attachment?.name || "No file choose"}
                  btnLabel="Choose file"
                  handleFileInputChange={handleFileInputChange}
                  handleFileDelete={handleFileDelete}
                  //handleUpload={handleFileUpload}
                  acceptType="application/pdf"
                  error={!!error.file}
                />
              </RowLabelRenderer>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "start" }}>
            <PrimaryLoadingButton
              btnLabel="Save"
              handleClick={async () => {
                let submitResult = await handleConfirm(addBtnHandler);
                if (submitResult?.data?.status) {
                  return handleClose();
                }
                toast.update(submitResult?.toastId, {
                  autoClose: false,
                });
              }}
              disabled={
                !validateForm(
                  {
                    name: input.name,
                    referenceNumber: input.referenceNumber,
                    startTime: input.startTime,
                    dueTime: input.dueTime,
                    siteApplied: input.siteApplied,
                    file: input?.file || true,
                  },
                  error
                )
              }
              loading={isLoading}
            />
            <PrimaryBtn btnLabel="Cancel" handleClick={handleClose} />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
