const accessTokenKey = 'accessToken'

export const setAccessToken = (token) => {
  if(!token) {
    return localStorage.removeItem(accessTokenKey)
  }

  return localStorage.setItem(accessTokenKey, token)
}

export const getAccessToken = () => {
  return localStorage.getItem(accessTokenKey)
}
