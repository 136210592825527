import { axiosInstance } from "./base";

export const getAdobeSignConfigAPI = () =>
  axiosInstance.get("/api/adobeSign/config");

export const updateAdobeSignConfigAPI = (config) =>
  axiosInstance.post("/api/adobeSign/config", {
    ...config,
  });

export const getAdobeSignAuthValidAPI = () =>
  axiosInstance.get("/api/adobeSign/refreshToken");

export const getAdobeSignAuthorizeUrlAPI = () =>
  axiosInstance.get("/api/adobeSign/authRequest");

export const submitAdobeSignAuthCodeAPI = (codeAndState) =>
  axiosInstance.post("/api/adobeSign/code", {
    ...codeAndState,
  });
