import { useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { MInput } from "../common/MInputs";
import MDialogInUser from "./MDialogInUser";
import MDialogSetPwd from "../common/MDialogSetPwd";
import { RowLabelRenderer } from "../common/RowLabelRenderer";
import { useUserContext } from "../../lib/context/userContext";
const UserProfile = () => {
  const { userInfo, editHandler, pwdChangeHandler } = useUserContext();
  const UserInfoActionsRender = () => {
    return (
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ mt: 2 }}
        spacing={2}
      >
        <div>
          <MDialogInUser
            buttonLabel="Edit"
            title="Update User Profile"
            btnHandler={editHandler}
            data={{
              id: userInfo.id,
              name: userInfo.name,
              email: userInfo.email,
              userGroupId: userInfo.userGroupId,
              organizationId: userInfo.organizationId || "",
            }}
          />
        </div>

        <div>
          <MDialogSetPwd
            buttonLabel="Password"
            title="Change Password"
            btnHandler={pwdChangeHandler}
            data={userInfo}
            desc="Password must contain a length of at least 8 ~ 20 characters, at least one digit [0-9], at least one Latin character [A-Za-z] and at least one special character."
          />
        </div>
      </Stack>
    );
  };

  const UserInfoRender = () => {
    return (
      <>
        <Grid
          container
          rowSpacing={2}
          sx={{ maxWidth: "1240px", margin: "20px auto" }}
        >
          <RowLabelRenderer label="Name">
            <MInput value={userInfo.name} name="name" readOnly={true} />
          </RowLabelRenderer>
          <RowLabelRenderer label="Email">
            <MInput value={userInfo.email} name="name" readOnly={true} />
          </RowLabelRenderer>
          <RowLabelRenderer label="Organization">
            <MInput
              value={userInfo.organizationId ? userInfo.organization.name : "-"}
              name="name"
              readOnly={true}
            />
          </RowLabelRenderer>
          <RowLabelRenderer label="Role">
            <MInput
              value={userInfo && userInfo?.userGroup?.name}
              name="name"
              readOnly={true}
            />
          </RowLabelRenderer>
          <RowLabelRenderer label="Last Login Time">
            <MInput
              value={
                userInfo.lastLoginAt
                  ? new Date(userInfo.lastLoginAt).toLocaleString()
                  : "-"
              }
              name="name"
              readOnly={true}
            />
          </RowLabelRenderer>
        </Grid>
      </>
    );
  };

  return (
    userInfo && (
      <>
        <UserInfoRender />
        <UserInfoActionsRender />
      </>
    )
  );
};

export default UserProfile;
