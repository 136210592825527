import { useState } from "react";
import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/system";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import SearchBar from "./SearchBar";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#333",
    fontWeight: "bold",
    borderBottom: "1px solid #d0d0d0",
    padding: "12px",
  },
  [`&.${tableCellClasses.body}`]: {
    padding: "10px",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F2FAFC",
  },
  "&:hover": {
    backgroundColor: "#fafcff",
  },
}));

const defaultHeaderRenderer = (headers) => {
  return (
    <StyledTableRow>
      {headers.map((header, index) => {
        return (
          <StyledTableCell align="center" key={index}>
            <Button
              variant="text"
              color="secondary"
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },
                fontSize: "inherit",
                textTransform: "inherit",
                fontWeight: "bold",
              }}
            >
              {header}
            </Button>
          </StyledTableCell>
        );
      })}
    </StyledTableRow>
  );
};

const defaultRowLabelRenderer = (record) => {
  return (
    <StyledTableRow
      key={record}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <StyledTableCell
        component="th"
        scope="row"
        sx={{ color: "#D24D57", fontWeight: "bold" }}
        align="center"
      >
        {record}
      </StyledTableCell>
    </StyledTableRow>
  );
};
const defaultEmptyRowLabelRenderer = (headers) => (
  <StyledTableRow
    key={"empty"}
    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
  >
    <StyledTableCell align="center" colSpan={headers.length}>
      Empty records
    </StyledTableCell>
  </StyledTableRow>
);
const defaultFooterRenderer = (records) =>
  records instanceof Array ? `${records.length} records` : "";

export default function EnhancedTable({
  tableConfig,
  search = true,
  setTableConfig,
  headers = ["#", "edit", "delete"],
  records = [],
  rowRenderer = defaultRowLabelRenderer,
  emptyRowLabelRenderer = defaultEmptyRowLabelRenderer,
  footerRenderer = defaultFooterRenderer,
  totalLength = 0,
  filtersRenderer = null,
  initTableConfig = {
    searchKeyword: "",
    startIndex: 0,
    rowsPerPage: 20,
    order: "",
    orderBy: "",
    filters: null,
    page: 0,
  },
}) {
  const [searchInput, setSearchInput] = useState("");
  const { searchKeyword, rowsPerPage, order, orderBy, page } = tableConfig;
  const sortClick = (property) => {
    const isAsc = orderBy === property && order === "asc";
    //console.log("property", property);
    //console.log("orderBy", orderBy);
    setTableConfig({
      ...tableConfig,
      startIndex: 0,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
      page: 0,
    });
  };
  const checkDirection = (header) => {
    if (order) {
      if (orderBy === header.sortBy) {
        return order;
      } else {
        return "desc";
      }
    } else {
      return "desc";
    }
  };
  const HeaderRenderer = () => {
    return (
      <StyledTableRow>
        {headers.map((header, index) => (
          <StyledTableCell key={index} align="center">
            {header.sortBy ? (
              <TableSortLabel
                active={orderBy === header.name}
                onClick={() => sortClick(header.sortBy)}
                direction={checkDirection(header)}
                hideSortIcon={true}
              >
                {header.name}
                {orderBy === header.name ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <TableSortLabel hideSortIcon={true} disabled={true}>
                {header.name}
              </TableSortLabel>
            )}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    );
  };
  const handleSearchInput = (searchInput) => {
    //setTableConfig({ ...tableConfig, searchKeyword: searchInput });
    setSearchInput(searchInput);
  };
  const searchBtnClick = () => {
    if (searchInput.trim().length > 0) {
      setTableConfig({
        ...tableConfig,
        //order: "",
        page: 0,
        startIndex: 0,
        searchKeyword: searchInput,
      });
      // const orderVal = orderBy ? `${orderBy}:${order}` : "";

      // handleRequest(searchInput, listIndex, rowsPerPage, orderVal);
    }
  };
  const handleReset = () => {
    setTableConfig(initTableConfig);
    setSearchInput("");
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (0 + page) * rowsPerPage - totalLength) : 0;

  const handleChangePage = (event, newPage) => {
    setTableConfig({
      ...tableConfig,
      page: newPage,
      startIndex: newPage * rowsPerPage,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setTableConfig({
      ...tableConfig,
      page: 0,
      startIndex: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };
  return (
    <>
      {search && (
        <SearchBar
          value={searchInput}
          onChange={handleSearchInput}
          onClickSearch={searchBtnClick}
          onClickReset={handleReset}
        />
      )}
      {filtersRenderer && filtersRenderer(tableConfig, setTableConfig)}
      <TableContainer
        component={Paper}
        sx={{
          border: "1px solid #d0d0d0",
          boxShadow: "none",
          maxWidth: "1240px",
          margin: "30px auto 20px",
        }}
      >
        <Table
          sx={{ minWidth: 650 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead>
            {headers ? <HeaderRenderer /> : defaultHeaderRenderer(headers)}
          </TableHead>

          <TableBody>
            {!records ||
              (records.length === 0 && emptyRowLabelRenderer(headers))}
            {records &&
              records.length > 0 &&
              records.map((record, index) => {
                return rowRenderer(record, index);
              })}

            {emptyRows > 0 && (
              <StyledTableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <StyledTableCell colSpan={headers.length} />
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100, 200]}
        component="div"
        count={totalLength}
        rowsPerPage={rowsPerPage}
        page={totalLength === 0 ? 0 : page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={({ from, to, count, page }) => {
          return `${from} - ${to} of ${count} records`;
        }}
      />
    </>
  );
}
