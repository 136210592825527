import {useApi} from "../lib/context/ApiContext";
import {Box, LinearProgress} from "@mui/material";

const TopLoadingBar = () => {
	const api = useApi()

	return (
		<Box position={'absolute'} top={0} left={0} right={0} bottom={'99vh'}>
			{api.isLoadingApi ? <LinearProgress/> : <></>}
		</Box>
	)
}

export default TopLoadingBar
