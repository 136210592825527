import { axiosInstance } from "./base";

export const getAllOrg = ({
  q = null,
  s = null,
  n = null,
  order = null,
  filters = null,
}) => {
  return axiosInstance.get("/api/organization", {
    params: {
      q,
      s,
      n,
      order,
      filters,
    },
  });
};

export const createOrg = (name) => {
  return axiosInstance.post("/api/organization", { name });
};

export const deleteOrg = (id) => {
  return axiosInstance.delete(`/api/organization/${id}`);
};

export const updateOrg = (id, name) => {
  return axiosInstance.post(`/api/organization/${id}`, { id, name });
};

export const getOrgById = (id) => {
  return axiosInstance.get(`/api/organization/${id}`);
};

export const getOrgUsers = (id) => {
  return axiosInstance.get(`/api/organization/${id}/users`);
};
