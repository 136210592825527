import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { CircularProgress, Container, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

import { useAuth } from "../../lib/context/AuthContext";

const SupplierLoginPage = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const isAuthenticated = auth.isAuthenticated;
  const isSupplier = auth.isSupplier();
  const [isLogin, setIsLogin] = useState(false);
  const [isError, setIsError] = useState(false);

  const [params] = useSearchParams();
  const token = params.get("token");
  useEffect(() => {
    const login = async () => {
      setIsLogin(true);

      if (token) {
        const success = await auth.loginSupplier(token);
        console.log(success);
        if (!success) {
          setIsError(true);
        }
      } else {
        setIsError(true);
      }
    };

    if (isAuthenticated) {
      if (isSupplier) {
        navigate(`/supplier/submission?token=${token}`);
      } else {
        navigate("/");
      }
    } else {
      if (!isLogin) {
        login().then((_) => {});
      }
    }
  }, [auth, navigate, isAuthenticated, isLogin, isSupplier, token]);
  // }, [isAuthenticated, isLogin]);

  return (
    <Container
      maxWidth={"md"}
      sx={{
        mt: 4,
        width: "460px",
        margin: "auto auto",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isLogin && !isError && (
        <>
          <Typography pb={3}>Logging you in...</Typography>
          <CircularProgress />
        </>
      )}
      {isLogin && isError && (
        <>
          <ErrorIcon fontSize="large" color={"error"} />
          <Typography>Login failed</Typography>
        </>
      )}
    </Container>
  );
};

export default SupplierLoginPage;
