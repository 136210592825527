import { useEffect } from "react";
import {
  withAdminAuthenticated,
  withAuthenticated,
} from "../../lib/context/AuthContext";
import MDialogInUsers from "../../components/common/MDialogInUsers";
import UsersList from "../../components/Users/UsersLists";
import MPage from "../../components/common/MPage";
import { useUsers } from "../../lib/hooks/useUsers";
const Users = () => {
  //const { addBtnHandler, users } = useUsers();

  const useUsersHooks = useUsers();
  const { fetchUsers, users, addBtnHandler, groups, orgs } = useUsersHooks;
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    users &&
    groups &&
    orgs && (
      <>
        <MPage
          title="Manage Users"
          header={
            <MDialogInUsers
              buttonLabel="Create User"
              title="Create User"
              data={{
                name: "",
                email: "",
                userGroupId: "",
                organizationId: "",
              }}
              btnHandler={addBtnHandler}
              groups={groups}
              orgs={orgs}
            />
          }
        >
          <UsersList useUsersHooks={useUsersHooks} />
        </MPage>
      </>
    )
  );
};

export default withAuthenticated(withAdminAuthenticated(Users));
