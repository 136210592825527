import { useEffect } from "react";
import { ArrowBackIos } from "@mui/icons-material";
import { useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";

import {
  withAdminAuthenticated,
  withAuthenticated,
} from "../../../lib/context/AuthContext";
import { Box, Container, Divider, IconButton } from "@mui/material";
import NavBar from "../../../components/common/NavBar";
import PageHeader from "../../../components/common/PageHeader";
import RenderSummary from "../../../components/RFQ/RenderSummary";
import useRfqInfo from "../../../lib/hooks/useRfqInfo";
import useRfqSummary from "../../../lib/hooks/useRfqSummary";

const SummaryPage = () => {
  const params = useParams();
  const { RFQId } = params;
  const [querys] = useSearchParams();
  const showQuotation = querys.get("showQuotation");

  const navigate = useNavigate();
  const { fetchRfq, rfq } = useRfqInfo();

  const rfqSummaryHook = useRfqSummary({ rfqId: RFQId });
  const { fetchRfqSubmissionSummary, rfqSubmissionList } = rfqSummaryHook;
  useEffect(() => {
    fetchRfq(RFQId);
    fetchRfqSubmissionSummary();
  }, [RFQId]);
  useEffect(() => {
    if (rfq && rfq.state === 5) {
      navigate(-1);
    }
  }, [rfq, navigate]);
  return (
    <>
      <NavBar />
      <Container maxWidth="lg" sx={{ my: 8 }}>
        <Box
          style={{ display: "flex", justifyContent: "space-between" }}
          sx={{ maxWidth: "1240px", margin: "0 auto" }}
        >
          <PageHeader title="Summary" />
          <IconButton
            size="medium"
            color="primary"
            edge={false}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIos fontSize="inherit" />
          </IconButton>
        </Box>
        <Divider sx={{ margin: "20px 0" }} />
        {rfq && rfq.state !== 5 && rfqSubmissionList && (
          <RenderSummary
            rfq={rfq}
            rfqSummaryHook={rfqSummaryHook}
            showQuotation={showQuotation}
          />
        )}
      </Container>
    </>
  );
};

export default withAuthenticated(withAdminAuthenticated(SummaryPage));
