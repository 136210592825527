import { useState, useCallback } from "react"
//import { axiosInstance } from "../api/base";
import { postFilePdf, getFile } from "../api/rfq"
import { useApi } from "../context/ApiContext"

export default function useAttachment(fileId) {
  const api = useApi()
  const [pdfUrl, setPdfUrl] = useState("")
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  //console.log("fileId useAttachment", fileId);
  const fetchFilePdf = useCallback(() => {
    const fetchingFilePdf = async () => {
      //console.log("fileId", fileId);
      const response = await api.runApi(() => getFile(fileId))
      if (response?.data?.data) {
        const fileRes = await postFilePdf(response?.data?.data)
        if (response.status === 200) {
          setPdfUrl(URL.createObjectURL(fileRes.data))
        }
      }
    }
    fetchingFilePdf()
  }, [fileId])

  //const fetchFilePdf = useCallback(() => {
  //  const fetchingFilePdf = async () => {
  //    const response = await postFilePdf(data);

  //    if (response.status === 200) {
  //      setPdfUrl(URL.createObjectURL(response.data));
  //    }
  //  };
  //  fetchingFilePdf();
  //}, []);

  return {
    pdfUrl,
    numPages,
    pageNumber,
    setNumPages,
    setPageNumber,
    fetchFilePdf,
  }
}
