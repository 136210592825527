import { Box } from "@mui/material";

import MDialogInUsers from "../common/MDialogInUsers";

import { ReconfirmBtn } from "../common/Buttons";
import { createHeaders } from "../../lib/utils/utils";
import MTable, { StyledTableCell, StyledTableRow } from "../common/MTable";
const headers = [
  createHeaders("Name", false, "name"),
  createHeaders("Email", false, "email"),
  createHeaders("Role", false, "userGroup.name"),
  createHeaders("Organization", false, "organization"),
  createHeaders("Action", false, null),
];
const UsersLists = ({ useUsersHooks }) => {
  const {
    users,
    usersLength,
    setTableConfig,
    tableConfig,
    editHandler,
    deleteHandler,
    orgs,
    groups,
  } = useUsersHooks;

  return (
    <>
      <Box sx={{ maxWidth: "1240px", margin: "0 auto" }}>
        <MTable
          tableConfig={tableConfig}
          setTableConfig={setTableConfig}
          search={true}
          headers={headers}
          records={users}
          totalLength={usersLength}
          rowRenderer={(record) => (
            <StyledTableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={record.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <StyledTableCell component="th" scope="row" padding="none">
                {record.name}
              </StyledTableCell>
              <StyledTableCell align="center">{record.email}</StyledTableCell>

              <StyledTableCell
                align="center"
                sx={{ textTransform: "capitalize" }}
              >
                {record?.userGroup?.name}
              </StyledTableCell>
              <StyledTableCell align="center">
                {record.organization ? record.organization.name : "-"}
              </StyledTableCell>

              <StyledTableCell align="center">
                <MDialogInUsers
                  buttonLabel="Edit"
                  title="Update User"
                  btnHandler={editHandler}
                  data={{
                    id: record.id,
                    name: record.name,
                    email: record.email,
                    userGroupId: record.userGroupId,
                    organizationId: record?.organizationId || "",
                  }}
                  groups={groups}
                  orgs={orgs}
                />
                <ReconfirmBtn
                  btnTitle="Delete"
                  desc="Delete this data?"
                  handleClick={() => deleteHandler(record.id)}
                />
              </StyledTableCell>
            </StyledTableRow>
          )}
        />
      </Box>
    </>
  );
};

export default UsersLists;
