import { TextField } from "@mui/material";
import { setMinutes, setSeconds, setMilliseconds, addHours } from "date-fns";

import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { formatRfqDate } from "../../lib/utils/utils";
export default function MDatePicker({
  currentTime = new Date(),
  value = "",
  handleChange = () => console.log(""),
  error = false,
  errorText = "",
}) {
  //handle key press event
  //const handleKeypress = (e) => {
  //  console.log(e);
  //  e.preventDefault();
  //  return false;
  //};
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          value={value}
          error={false}
          views={["day", "month", "hours"]}
          //minutesStep={60}
          inputFormat="yyyy/MM/dd HH:00"
          minDateTime={formatRfqDate(addHours(currentTime, 1))}
          disableMaskedInput={true}
          ampm={false}
          ampmInClock={false}
          mask="___/__/__ __:__"
          onChange={(newValue) => {
            handleChange(formatRfqDate(newValue));
          }}
          onChangeRaw={(e) => e.preventDefault()}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                className={`${error ? "error" : ""} date-picker`}
                //readOnly={true}
                onKeyPress={(e) => {
                  e.preventDefault();
                }}
              />
            );
          }}
        />
      </LocalizationProvider>
    </>
  );
}

export function MDatePickerWithoutTime({
  value = "",
  handleChange = () => console.log(""),
  error = false,
  errorText = "",
}) {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={value}
          inputFormat="yyyy/MM/dd"
          mask="____/__/__"
          onChange={handleChange}
          onChangeRaw={(e) => e.preventDefault()}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                className={`${error ? "error" : ""} date-picker`}
                //readOnly={true}
                onKeyPress={(e) => {
                  e.preventDefault();
                }}
              />
            );
          }}
        />
      </LocalizationProvider>
    </>
  );
}
