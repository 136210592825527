import { axiosInstance } from "./base";
import axios from "axios";
export const getAllRfqs = ({
  q = null,
  s = null,
  n = null,
  order = null,
  filters = null,
}) => {
  return axiosInstance.get(`/api/rfq`, {
    params: {
      q,
      s,
      n,
      order,
      filters,
    },
  });
};

export const createRfq = (newData) => {
  return axiosInstance.post(`/api/rfq`, {
    ...newData,
  });
};

export const updateRfq = (newData) => {
  return axiosInstance.post(`/api/rfq/${newData.id}`, {
    ...newData,
  });
};

export const deleteRfq = (id) => {
  return axiosInstance.delete(`/api/rfq/${id}`);
};

export const getRfqById = (id) => {
  return axiosInstance.get(`/api/rfq/${id}`);
};

export const unLockRfqById = (id, pwd) => {
  return axiosInstance.post(`/api/rfq/unlock/${id}`, {
    password: pwd,
  });
};
export const lockRfqById = (id) => {
  return axiosInstance.post(`/api/rfq/lock/${id}`, {
    id,
  });
};
export const terminatedRFQById = (id) => {
  return axiosInstance.post(`/api/rfq/terminate/${id}`);
};
export const uploadFile = (formData) => {
  return axiosInstance.post(`/api/file`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getFile = (id) => {
  return axiosInstance.get(`/api/file/${id}`);
};

export const postFilePdf = (formData) => {
  return axiosInstance.post(`/api/file/pdf`, formData, {
    responseType: "blob",
  });
};

export const unlockAuth = (rfqId, email, password) => {
  return axiosInstance.post(`/api/rfq/view/${rfqId}`, { email, password });
};

export const exportRfqs = () => {
  return axiosInstance.get(`/api/rfq/export`);
};
