import { useCallback, useState } from "react";
import { useApi } from "../context/ApiContext";
import {
  createRfqSupplier,
  deletedRfqSupplier,
  getAllSupplierByRfqId,
  getRfqSupplierById,
  updateRfqSupplier,
} from "../api/suppliers";
import { sendEmailSupplierByIdAndRfqId } from "../api/notification";

export default function useRfqSuppliers({ rfqId }) {
  const api = useApi();
  const [tableConfig, setTableConfig] = useState({
    searchKeyword: "",
    startIndex: 0,
    rowsPerPage: 20,
    order: "",
    orderBy: "",
    filters: null,
    page: 0,
  });
  const [rfqSuppliersList, setRfqSuppliersList] = useState([]);
  const [rfqSuppliersLength, setRrfqSuppliersLength] = useState(0);
  const [rfqSupplier, setRfqSupplier] = useState(null);

  const fetchRfqSupplierList = useCallback(() => {
    const fetchingRfqSupplierList = async () => {
      const response = await api.runApi(() =>
        getAllSupplierByRfqId(rfqId, {
          q: tableConfig.searchKeyword,
          s: tableConfig.startIndex,
          n: tableConfig.rowsPerPage,
          order: tableConfig.orderBy
            ? `${tableConfig.orderBy}:${tableConfig.order}`
            : "createdAt:desc",
        })
      );
      setRfqSuppliersList(response?.data?.data?.rfqSupplierList || []);
      setRrfqSuppliersLength(response?.data?.data?.totalLength || 0);
    };
    fetchingRfqSupplierList();
  }, [tableConfig]);

  const fetchRfqSupplier = useCallback((id) => {
    const fetchingRfq = async () => {
      const response = await api.runApi(() => getRfqSupplierById(id));

      setRfqSupplier(response?.data?.data);
    };
    fetchingRfq();
  }, []);

  const addBtnHandler = async (newRfqInput) => {
    await api.runApi(() => createRfqSupplier({ ...newRfqInput, rfqId }));
    fetchRfqSupplierList();
  };

  const deleteHandler = async ({ id }) => {
    if (id) {
      await api.runApi(() => deletedRfqSupplier(id));
    }
    fetchRfqSupplierList();
  };

  const editHandler = async (newData) => {
    await api.runApi(() => updateRfqSupplier(newData));
    fetchRfqSupplierList();
  };

  const sendMail = async (supplierId, templateId, recipient) => {
    await api.runApi(() =>
      sendEmailSupplierByIdAndRfqId(supplierId, templateId, rfqId, recipient)
    );
  };

  return {
    rfqSuppliersLength,
    setRrfqSuppliersLength,
    rfqSuppliersList,
    setRfqSuppliersList,
    rfqSupplier,
    setRfqSupplier,
    fetchRfqSupplierList,
    fetchRfqSupplier,
    addBtnHandler,
    deleteHandler,
    editHandler,
    sendMail,
    tableConfig,
    setTableConfig,
  };
}
