import { Navigate } from "react-router";
import { withAuthenticated } from "../../../lib/context/AuthContext";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAdobeSign } from "../../../lib/context/AdobeSignContext";
import { toast } from "react-toastify";

const AdobeSignCode = () => {
  const [searchParams] = useSearchParams();
  const adobeSign = useAdobeSign();

  const code = searchParams.get("code");
  const state = searchParams.get("state");

  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    console.log("fetch code");
    adobeSign
      .submitAdobeSignAuthCode({
        code,
        state,
      })
      .then((response) => {
        console.log(response);
        if (response.status) {
          toast.info("Updated Adobe Sign Authentication");
          <Navigate to={"/system/config/adobeSign"} />;
        } else {
          toast.error("Update Adobe Sign Authentication Failed");
        }
        setCompleted(true);
      });
  }, [code, state]);

  if (!code || !state) {
    return <Navigate to={"/"} />;
  }

  if (completed) {
    return <Navigate to={"/system/config/adobeSign"} />;
  }

  return null;
};

export default withAuthenticated(AdobeSignCode);
