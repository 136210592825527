import { useEffect } from "react";
import { format, isValid } from "date-fns";
import { Edit, Link as LinkIcon } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuth } from "../../lib/context/AuthContext";
import { checkState, validateForm } from "../../lib/utils/utils";
import { useRfqs } from "../../lib/context/RfqsContext";
import {
  PrimaryBtn,
  PrimaryIconBtn,
  PrimaryLoadingButton,
} from "../common/Buttons";
import {
  MDropdown,
  MFileInput,
  MInput,
  TextareaAutosizeSideLable,
} from "../common/MInputs";
import MDatePicker from "../common/MDatePicker";
import { RowLabelRenderer } from "../common/RowLabelRenderer";

const checkReadOnly = (state, isReadMode) => {
  let isReadOnly = false;
  if (state !== 0) {
    isReadOnly = true;
  } else {
    if (isReadMode) {
      isReadOnly = true;
    }
  }

  return isReadOnly;
};

const RenderRfqInfo = ({ rfqInfoHook, isReadMode, toggleIsReadMode }) => {
  let navigate = useNavigate();
  const { isFC } = useAuth();
  const { isAdminRole } = useAuth();
  const { editHandler, isShowCompleted } = useRfqs();
  const {
    attachment,
    input,
    setInput,
    error,
    handleFileInputChange,
    handleFileDelete,
    validateInput,
    handleInputChange,
    handleOptions,
    handleConfirm,
    setRfqOwner,
    orgs,
    rfq,
    users,
    handleCancel,
    isLoading,
  } = rfqInfoHook;
  useEffect(() => {
    setInput({
      ...rfq,
      startTime: new Date(rfq.startTime).toISOString(),
      dueTime: new Date(rfq.dueTime).toISOString(),
    });
  }, [rfq]);

  return (
    <>
      {input && (
        <>
          <Box
            className="rfq-info-wrapper"
            style={{ display: "flex", justifyContent: "space-between" }}
            sx={{ maxWidth: "1240px", margin: "0 auto" }}
          >
            <Grid container rowSpacing={2} sx={{ margin: "auto" }}>
              <RowLabelRenderer label="RFQ Name">
                <MInput
                  name="name"
                  value={input.name}
                  handleChange={(e) => handleInputChange(e)}
                  errorText={error.name ? error.name : ""}
                  error={!!error.name}
                  readOnly={checkReadOnly(rfq.state, isReadMode)}
                />
              </RowLabelRenderer>
              <RowLabelRenderer label="Reference Number">
                <MInput
                  name="referenceNumber"
                  value={input.referenceNumber}
                  handleChange={(e) => handleInputChange(e)}
                  errorText={error.referenceNumber ? error.referenceNumber : ""}
                  error={!!error.referenceNumber}
                  readOnly={checkReadOnly(rfq.state, isReadMode)}
                />
              </RowLabelRenderer>

              {checkReadOnly(rfq.state, isReadMode) ? (
                <>
                  <RowLabelRenderer label="Start Date">
                    <MInput
                      name="startTime"
                      value={format(
                        new Date(input.startTime),
                        "dd/MM/yyyy HH:mm"
                      )}
                      readOnly={isReadMode}
                      type="text"
                    />
                  </RowLabelRenderer>
                  <RowLabelRenderer label="Due Date">
                    <MInput
                      name="dueTime"
                      value={format(
                        new Date(input.dueTime),
                        "dd/MM/yyyy HH:mm"
                      )}
                      readOnly={isReadMode}
                      type="text"
                    />
                  </RowLabelRenderer>
                </>
              ) : (
                <>
                  <RowLabelRenderer label="Start Date">
                    <MDatePicker
                      error={!!error.startTime}
                      value={input.startTime}
                      handleChange={(newValue) => {
                        if (newValue && isValid(newValue)) {
                          const newDate = new Date(newValue).toISOString();
                          setInput({ ...input, startTime: newDate });
                          if (input?.dueTime) {
                            validateInput("dueTime", input.dueTime);
                          }
                          return validateInput("startTime", newDate);
                        }
                      }}
                    />
                  </RowLabelRenderer>
                  <RowLabelRenderer label="Due Date">
                    <MDatePicker
                      error={!!error.dueTime}
                      value={input.dueTime}
                      handleChange={(newValue) => {
                        if (newValue && isValid(newValue)) {
                          const newDate = new Date(newValue).toISOString();
                          setInput({ ...input, dueTime: newDate });
                          if (input?.startTime) {
                            validateInput("startTime", input.startTime);
                          }
                          return validateInput("dueTime", newDate);
                        }
                      }}
                    />
                  </RowLabelRenderer>
                </>
              )}

              {checkReadOnly(rfq.state, isReadMode) ? (
                <RowLabelRenderer label="Site Applied">
                  <MInput
                    name="siteApplied"
                    value={rfq.organization.name || "-"}
                    readOnly={isShowCompleted || isReadMode}
                    type="text"
                  />
                </RowLabelRenderer>
              ) : (
                <RowLabelRenderer label="Site Applied">
                  <MDropdown
                    dropdownLabel="Please Select"
                    name="siteApplied"
                    handleChange={(e) => handleOptions(e)}
                    options={orgs}
                    value={input?.siteApplied || ""}
                    error={!!error.siteApplied}
                    errorText={error.siteApplied}
                  />
                </RowLabelRenderer>
              )}
              <RowLabelRenderer label="Edit Description">
                <TextareaAutosizeSideLable
                  name="description"
                  value={input.description}
                  handleChange={handleInputChange}
                  readOnly={checkReadOnly(rfq.state, isReadMode)}
                />
              </RowLabelRenderer>
              {checkReadOnly(rfq.state, isReadMode) ? (
                <>
                  <RowLabelRenderer label="T&amp;C Attachment ">
                    <PrimaryIconBtn
                      btnLabel="View"
                      handleClick={() => {
                        if (rfq?.fileId) {
                          return navigate(
                            `/rfq/viewAttachment?pageTitle=T%26C%20Attachment&rfqFileId=${rfq?.fileId}`
                          );
                        }
                      }}
                      icon={<LinkIcon />}
                      disabled={!input.fileId}
                    />
                  </RowLabelRenderer>
                </>
              ) : (
                <>
                  <RowLabelRenderer label="T&amp;C Attachment (<10MB)">
                    <MFileInput
                      name="attachment"
                      value={attachment?.name || "No file choose"}
                      btnLabel="Choose file"
                      handleFileInputChange={handleFileInputChange}
                      handleFileDelete={handleFileDelete}
                      //handleUpload={handleFileUpload}
                      acceptType="application/pdf"
                      error={!!error.file}
                    />
                  </RowLabelRenderer>
                </>
              )}
              <RowLabelRenderer label="RFQ Status">
                <MInput
                  name="state"
                  value={checkState(input)}
                  readOnly={true}
                />
              </RowLabelRenderer>
              {checkReadOnly(rfq.state, isReadMode) ? (
                <RowLabelRenderer label="RFQ Owner">
                  <MInput
                    name="owner"
                    value={input?.user?.name || ""}
                    readOnly={true}
                  />
                </RowLabelRenderer>
              ) : (
                isAdminRole() && (
                  <RowLabelRenderer label="RFQ Owner">
                    <MDropdown
                      dropdownLabel="Resign"
                      name="owner"
                      handleChange={(e) => {
                        setInput({ ...input, owner: e.target.value });
                        setRfqOwner({ id: e.target.value });
                      }}
                      options={users.filter(
                        (item) =>
                          item.userGroup.name.toLowerCase() !==
                          "financial controller"
                      )}
                      value={input?.owner || ""}
                      disabled={!isAdminRole()}
                    />
                  </RowLabelRenderer>
                )
              )}
              <Grid
                item
                xs={4}
                md={4}
                sx={{ marginTop: "20px", alignSelf: "center" }}
              >
                {rfq.state === 0 && !isFC() && (
                  <>
                    {isReadMode ? (
                      <PrimaryIconBtn
                        btnLabel="Edit"
                        handleClick={toggleIsReadMode}
                        icon={<Edit />}
                      />
                    ) : (
                      <>
                        <PrimaryLoadingButton
                          btnLabel="Save"
                          handleClick={async () => {
                            let submitResult = await handleConfirm(editHandler);
                            if (submitResult?.data?.status) {
                              return toggleIsReadMode();
                            }
                            toast.update(submitResult?.toastId, {
                              autoClose: false,
                            });
                          }}
                          disabled={
                            !validateForm(
                              {
                                name: input.name,
                                referenceNumber: input.referenceNumber,
                                startTime: input.startTime,
                                dueTime: input.dueTime,
                                siteApplied: input.siteApplied,
                                file: input?.file || true,
                              },
                              error
                            )
                          }
                          loading={isLoading}
                        />

                        <PrimaryBtn
                          btnLabel="Cancel"
                          handleClick={() => {
                            handleCancel();
                            toggleIsReadMode();
                          }}
                        />
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default RenderRfqInfo;
