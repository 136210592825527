import { axiosInstance } from "./base";

export const getAllSupplierByRfqId = (
  rfqId,
  { q = null, s = null, n = null, order = null, filters = null }
) => {
  return axiosInstance.get(`/api/rfqSupplier/${rfqId}/suppliers`, {
    params: {
      q,
      s,
      n,
      order,
      filters,
    },
  });
};

export const getRfqSupplierById = (id) => {
  return axiosInstance.get(`/api/rfqSupplier/${id}`);
};

export const createRfqSupplier = (newData) => {
  return axiosInstance.post(`/api/rfqSupplier`, newData);
};

export const updateRfqSupplier = (newData) => {
  return axiosInstance.post(`/api/rfqSupplier/${newData.id}`, newData);
};

export const deletedRfqSupplier = (id) => {
  return axiosInstance.delete(`/api/rfqSupplier/${id}`);
};

export const getSuppliersByRfqId = (rfqId) => {
  return axiosInstance.get(`/api/rfqSupplier/${rfqId}`);
};

export const supplierGetMe = (token) => {
  return axiosInstance.get("/api/rfqSupplier/me");
};
