import { Container, Stack, TextField } from "@mui/material";
import { useState } from "react";

import { useAuth } from "../lib/context/AuthContext";
import { PrimaryBtn } from "../components/common/Buttons";
import { Navigate } from "react-router";

const LoginPage = () => {
  const { isAuthenticated, login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLoginBtn = async (e) => {
    e.preventDefault();
    await login(email, password);
  };
  if (isAuthenticated) {
    return <Navigate to={"/"} />;
  }

  return (
    <Container
      maxWidth="md"
      sx={{
        mt: 4,
        width: "460px",
        margin: "auto auto",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack
        component="form"
        direction="column"
        spacing={2}
        sx={{
          background: "#ffffff",
          borderRadius: "20px",
          py: 4,
          px: 4,
          margin: "auto auto",
          maxWith: "800px",
        }}
      >
        <div>
          <img src="/static/HarbourPlaza.png" width={360} height={200} />
        </div>
        <TextField
          className="login-input"
          variant="outlined"
          color="secondary"
          label="Email"
          value={email}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
          required
          sx={{ background: "#F4F3F7", borderRadius: "10px", fontSize: "14px" }}
        />
        <TextField
          type="password"
          className="login-input"
          variant="outlined"
          color="secondary"
          label="Password"
          value={password}
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
          required
          sx={{ background: "#F4F3F7", borderRadius: "10px", fontSize: "14px" }}
        />

        <PrimaryBtn
          btnLabel="Login"
          handleClick={handleLoginBtn}
          type="submit"
        />
      </Stack>
    </Container>
  );
};

export default LoginPage;
