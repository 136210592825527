import {Typography} from "@mui/material";

const PageHeader = ({title}) => {
	return (
		<Typography sx={{mt: 0, fontWeight: "bold"}} variant="h1" color="#359DB4">
			{title}
		</Typography>
	);
};

export default PageHeader;
