import { axiosInstance } from "./base";

export const getAdobeSignWidgetId = (supplierId) =>
  axiosInstance.get(`api/adobeSign/widget/${supplierId}`);

export const getAdobeSignUrl = (widgetId) =>
  axiosInstance.get(`api/adobeSign/widget/${widgetId}/url`);

export const getAdobeSignComplete = (supplierId) =>
  axiosInstance.get(`api/adobeSign/widget/${supplierId}/complete`);
